//
// useApi.j
//

import { StateContext } from "@context/StateContext";
import { databaseNameExist } from "@custom-hooks/databases/index";
import { nodeIsWorking } from "@custom-hooks/nodes";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useSetError } from "@custom-hooks/useSetError";
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
import {
  analyzeReturnData,
  fetchApiRoute,
  renderAnalyzer,
  swrFetcher,
  tableNameExist,
} from "@lib/client-side";
import { deepCopyMap, generateRandomId } from "@lib/iso-utils";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import useSWRInfinite from "swr/infinite";

const USE_LOCAL_GATEWAY = process.env.NEXT_PUBLIC_USE_LOCAL_GATEWAY;
const EXPERIMENTAL_COMPANIES_ID =
  process.env.NEXT_PUBLIC_EXPERIMENTAL_COMPANIES_ID;
const experimentalCompaniesId = EXPERIMENTAL_COMPANIES_ID.split(",");
const LOGS_URL = process.env.NEXT_PUBLIC_LOGS_URL;
const LOGS_APIKEY = process.env.NEXT_PUBLIC_LOGS_APIKEY;
const BILLING_API_URL = process.env.NEXT_PUBLIC_BILLING_API_URL;

// #region BUILD Related
function useGetBuildId() {
  const { data, error, isValidating } = useSWR(
    ["/api/build-id", "useGetBuildId"],
    swrFetcher,
    {
      revalidateOnFocus: true,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    buildId: data !== undefined ? data.buildId : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}
// #endregion

// #region USER Related
function useGetUser() {
  const { data, error, isValidating } = useSWR(
    ["/api/user", "useGetUser"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    user: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetUserAccountProfile(provider) {
  const { data, error, isValidating } = useSWR(
    () => [
      `/api/provider-profile?provider=${provider}`,
      "useGetUserAccountProfile",
    ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    accountProfile: data !== undefined ? data : undefined,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetSqlitecloudCompanyUser() {
  //get user
  const { user: userInfo } = useGetUser();
  //get from StateContext user simulation
  const { simulateGenericUser } = useContext(StateContext);
  const [isSqlitecloudCompanyUser, setIsSqlitecloudCompanyUser] =
    useState(false);
  useEffect(() => {
    if (userInfo) {
      const companyId = JSON.parse(
        userInfo.organizations
      )[0].organization_id.toString();
      const testCompany = experimentalCompaniesId.includes(companyId);
      if (!testCompany) {
        setIsSqlitecloudCompanyUser(false);
      } else if (testCompany) {
        if (simulateGenericUser) {
          setIsSqlitecloudCompanyUser(false);
        } else {
          setIsSqlitecloudCompanyUser(true);
        }
      }
    }
  }, [userInfo, simulateGenericUser]);

  return isSqlitecloudCompanyUser;
}

// #endregion

// #region ROUTE related API
/**
 * in case of not existing projectId among the user projects, this method return FALSE
 */
function useGetActualProjectId() {
  //actual projectId
  const [actualProejctId, setActualProejctId] = useState(undefined);
  // get all user projects
  const { projects } = useGetProjects();
  //useRouter
  // const { query } = useRouter();
  const { query } = useCustomRouter();
  //set actual proejct id based on query paramenter checking that query project id exists between user projects
  useEffect(() => {
    if (projects && Array.isArray(projects) && query.projectId) {
      if (projects.some((obj) => obj.id === query.projectId)) {
        setActualProejctId(query.projectId);
      } else {
        setActualProejctId(false);
      }
    } else {
      setActualProejctId(undefined);
    }
  }, [query, projects]);

  return actualProejctId;
}

function useGetActualAndPrevProjectId() {
  //actual projectId
  const [actualProejctId, setActualProejctId] = useState(undefined);
  const [prevProejctId, setPrevProejctId] = useState(undefined);
  // get all user projects
  const { projects } = useGetProjects();
  //useRouter
  const { query } = useRouter();
  //set actual proejct id based on query paramenter checking that query project id exists between user projects
  useEffect(() => {
    if (projects && Array.isArray(projects) && query.projectId) {
      if (projects.some((obj) => obj.id === query.projectId)) {
        setPrevProejctId(actualProejctId);
        setActualProejctId(query.projectId);
      } else {
        setActualProejctId(false);
        setPrevProejctId(false);
      }
    } else {
      setActualProejctId(undefined);
      setPrevProejctId(undefined);
    }
  }, [query, projects]);

  return { actualProejctId, prevProejctId };
}

function useGetActualNodeId() {
  //useRouter
  const { query } = useRouter();
  if (query.nodeId) {
    return query.nodeId;
  } else {
    return undefined;
  }
}

function useGetActualDatabaseName() {
  //useRouter
  const { query } = useRouter();
  if (query.databaseName) {
    return query.databaseName;
  } else {
    return undefined;
  }
}

function useGetActualTableName() {
  //useRouter
  const { query } = useRouter();
  if (query.tableName) {
    return query.tableName;
  } else {
    return undefined;
  }
}

function useGetActualPathname() {
  const projectId = useGetActualProjectId();
  let { pathname } = useRouter();
  pathname = pathname.replace("[projectId]", projectId);
  return pathname;
}

function useGetActualQuery() {
  let { query } = useRouter();
  return query;
}

// #endregion

// #region PROJECTS related API
function useGetProjects() {
  const { data, error, isValidating } = useSWR(
    ["/api/projects", "useGetProjects"],
    swrFetcher,
    {
      revalidateOnFocus: true,
    }
  );
  analyzeReturnData(data);
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    projects: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetProject(projectId) {
  let actualProjectId = useGetActualProjectId();
  let projectIdToUse;
  if (projectId) {
    projectIdToUse = projectId;
  } else {
    projectIdToUse = actualProjectId;
  }
  const { data, error, isValidating } = useSWR(
    () =>
      projectIdToUse && [`/api/projects/${projectIdToUse}`, "useGetProject"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    project: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetProjectRegions() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `/api/projects/${projectId}/regions`,
        "useGetProjectRegions",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { showLoader } = renderAnalyzer(data, error, isValidating, "backend");

  return {
    projectRegions: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetProjectRoles() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [`/api/projects/${projectId}/roles`, "useGetProjectRoles"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    projectRoles: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetProjectPrivileges() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `/api/projects/${projectId}/privileges`,
        "useGetProjectPrivileges",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    projectPrivileges: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetProjectInfo(projectId) {
  //get actual url
  let url = "";
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }
  //based on deploy enviroment define dns record
  const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
  let dnsRecord = ".sandbox.sqlite.cloud";
  if (vercelEnv && vercelEnv === "production") {
    dnsRecord = ".sqlite.cloud";
  }
  //get actual project info
  const { project, showLoader } = useGetProject(projectId);
  //build project data
  const id = project && project.id ? project.id : undefined;
  const description =
    project && project.description ? project.description : undefined;
  const name = project && project.name ? project.name : undefined;
  const adminApiKey =
    project && project.admin_apikey ? project.admin_apikey : undefined;
  const adminPassword =
    project && project.admin_password ? project.admin_password : undefined;
  const adminUsername =
    project && project.admin_username ? project.admin_username : undefined;
  const connectionStringPsw =
    project && id && adminUsername && adminPassword
      ? `sqlitecloud://${adminUsername}:${adminPassword}@${id}${dnsRecord}:8860`
      : undefined;
  const connectionStringApiKey =
    project && project.connection_string
      ? project.connection_string
      : undefined;
  const gatewayString =
    project && project.gateway_string ? project.gateway_string : undefined;
  const extractDeployment = (url) => {
    const regex = /\/([^\/:]+):/;
    const matches = url.match(regex);
    if (matches && matches.length > 1) {
      const extractedPart = matches[1];
      return extractedPart;
    } else {
      return "not defined";
    }
  };
  const deployment = connectionStringApiKey
    ? extractDeployment(connectionStringApiKey)
    : undefined;
  let gatewayDeployment = "";
  let gatewayUrlHTTP = "";
  let gatewayUrlWS = "";
  if (USE_LOCAL_GATEWAY && USE_LOCAL_GATEWAY.toLowerCase() === "true") {
    gatewayDeployment = url && "localhost";
    gatewayUrlHTTP = url && `http://localhost:8090`;
    gatewayUrlWS =
      url && `${url.protocol === "https:" ? "wss" : "ws"}://localhost:4000`;
  } else {
    gatewayDeployment = url && deployment;
    gatewayUrlHTTP = url && gatewayString;
    // console.log(gatewayUrlHTTP) //TOGLI
    // gatewayUrlHTTP = url && deployment && `https://${deployment}:8090`;
    gatewayUrlWS = url && deployment && `wss://${deployment}:4000`;
  }
  return {
    showLoader,
    deployment,
    id,
    name,
    description,
    adminApiKey,
    adminPassword,
    adminUsername,
    connectionStringApiKey,
    connectionStringPsw,
    gatewayDeployment,
    gatewayUrlHTTP,
    gatewayUrlWS,
  };
}

// #endregion

// #region NODES related API
function useGetNodes(projectId) {
  let actualProjectId = useGetActualProjectId();
  let projectIdToUse;
  if (projectId) {
    projectIdToUse = projectId;
  } else {
    projectIdToUse = actualProjectId;
  }
  const { data, error, isValidating } = useSWR(
    () =>
      projectIdToUse && [
        `/api/projects/${projectIdToUse}/nodes`,
        "useGetNodes",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  analyzeReturnData(data);
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    nodes: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetActualNodesNumber(prjId) {
  const { nodes } = useGetNodes(prjId);
  const [actualNodesNumber, setActualNodesNumber] = useState(undefined);

  useEffect(() => {
    if (nodes && Array.isArray(nodes) && nodes.length >= 0) {
      const actualNodesNumber = nodes.length;
      setActualNodesNumber(actualNodesNumber);
    }
  }, [nodes]);

  return actualNodesNumber;
}

function useGetNode(nodeId) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeId !== null && [
        `/api/projects/${projectId}/nodes/${nodeId}`,
        "useGetNode",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    node: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetNodeStat(node) {
  const projectId = useGetActualProjectId();
  const nodeId = node ? node.node_id : undefined;
  const isWorking = nodeIsWorking(node);
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeId &&
      isWorking && [
        `/api/projects/${projectId}/nodes/${nodeId}/stat`,
        "useGetNodeStat",
      ],
    swrFetcher,
    {
      refreshInterval: 300000,
      revalidateOnFocus: false,
      // shouldRetryOnError: false
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    stat: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetNodeStatGateway(node) {
  //get actual url
  const url = new URL(window.location.href);
  //read user company
  const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();
  //check if node is working
  const isWorking = nodeIsWorking(node);
  //get connection string to node
  const { gatewayUrlHTTP, connectionStringApiKey } = useGetProjectInfo();
  //generate nodes url
  let nodeUrl = "";
  if (USE_LOCAL_GATEWAY && USE_LOCAL_GATEWAY.toLowerCase() === "true") {
    nodeUrl = "http://localhost:8090";
  } else {
    nodeUrl = `https://${node.address}:8090`;
  }
  const { data, error, isValidating } = useSWR(
    () =>
      connectionStringApiKey &&
      isWorking &&
      isSqlitecloudCompanyUser && [
        `${nodeUrl}/v1/stats`,
        "useGetNodeStatGateway",
        "",
        connectionStringApiKey,
      ],
    swrFetcher,
    {
      refreshInterval: 300000,
      revalidateOnFocus: false,
      // shouldRetryOnError: false
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    stat: data !== undefined ? data.data : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetNodeConnections(nodeId) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeId !== null && [
        `/api/projects/${projectId}/nodes/${nodeId}/connections`,
        "useGetNodeConnections",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    connections: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetNodeLogs(nodeId, queryStrings) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId !== null &&
      nodeId !== null && [
        `/api/projects/${projectId}/nodes/${nodeId}/logs`,
        "useGetNodeLogs",
        queryStrings,
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const showLoader = (!error && !data) || isValidating;

  return {
    logs: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetProjectLogsInfo() {
  const projectId = useGetActualProjectId();

  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `${LOGS_URL}${projectId}/info`,
        "useGetProjectLogsInfo",
        "",
        LOGS_APIKEY,
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "gateway"
  );

  return {
    info: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetProjectLogs(
  filters = {},
  live = false,
  swr = true,
  queryInUrl = false,
  id
) {
  //states
  const [last, setLast] = useState(false);
  const [logs, setLogs] = useState(undefined);

  const multiplier = 15;
  //generate query string to be updated in the url
  // if (queryInUrl && swr) {
  // const urlFilterCopy = _.cloneDeep(filters);
  // //convert time to UTC
  // if (urlFilterCopy["fromTime"]) {
  // 	urlFilterCopy["fromTime"] = convertDateToUtc0(urlFilterCopy["fromTime"]);
  // }
  // if (urlFilterCopy["toTime"]) {
  // 	urlFilterCopy["toTime"] = convertDateToUtc0(urlFilterCopy["toTime"]);
  // }
  // //remove key to not be saved in url query string
  // delete urlFilterCopy["createdAt"];
  // const queryStringsForUrl = Object.entries(urlFilterCopy).map((obj) => obj[1] && obj[1] != "" ? Array.isArray(obj[1]) ? obj[1].map((val) => val && val != "" ? `${obj[0]}=${val}` : undefined) : `${obj[0]}=${obj[1]}` : undefined);
  // let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryStringsForUrl.flat().filter(x => typeof x === 'string' && x.length > 0).join('&');
  // console.log(id + ", HERE")
  // window.history.pushState({}, '', newUrl);
  // }
  //generated query string to be send to gateway removing timeline, fromTime, toTime
  const gatewayFilterCopy = _.cloneDeep(filters);
  delete gatewayFilterCopy["timeline"];
  delete gatewayFilterCopy["fromTime"];
  delete gatewayFilterCopy["toTime"];
  const queryStrings = Object.entries(gatewayFilterCopy).map((obj) =>
    obj[1] && obj[1] != ""
      ? Array.isArray(obj[1])
        ? obj[1].map((val) =>
            val && val != "" ? `${obj[0]}=${val}` : undefined
          )
        : `${obj[0]}=${obj[1]}`
      : undefined
  );
  const projectId = useGetActualProjectId();
  const getKey = (pageIndex, previousPageData) => {
    if (!swr) return null;
    if (previousPageData && !previousPageData.data) return null;
    if (pageIndex === 0)
      return () =>
        projectId !== null && [
          `${LOGS_URL}${projectId}?` +
            queryStrings
              .flat()
              .filter((x) => typeof x === "string" && x.length > 0)
              .join("&") +
            `&limit=${multiplier}`,
          "useGetProjectLogs",
          "",
          LOGS_APIKEY,
        ];
    return () =>
      projectId && [
        `${LOGS_URL}${projectId}?` +
          queryStrings
            .flat()
            .filter((x) => typeof x === "string" && x.length > 0)
            .join("&") +
          `&limit=${multiplier}` +
          `&offset=${pageIndex * multiplier}`,
        "useGetProjectLogs",
        "",
        LOGS_APIKEY,
      ];
  };
  const { data, error, isValidating, size, setSize } = useSWRInfinite(
    getKey,
    swrFetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: live,
      revalidateAll: live,
      refreshInterval: live ? 5000 : 0,
    }
  );

  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data ? data[0] : undefined,
    error,
    isValidating,
    "gateway"
  );

  //concatenate logs received from gateway in different pages
  useEffect(() => {
    if (data) {
      const concatenatedLogs = { data: [] };
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(data) &&
        data.forEach((log) => {
          if (log.data.length > 0) {
            if (last) setLast(false);
            if (concatenatedLogs.data.length > 0) {
              concatenatedLogs.data = concatenatedLogs.data.concat(log.data);
            } else {
              concatenatedLogs.data = log.data;
            }
          } else {
            setLast(true);
          }
        });
      setLogs(concatenatedLogs.data);
    } else {
      setLogs(undefined);
    }
  }, [data]);

  //increment size in live mode
  const len =
    Math.trunc((logs && logs.length > 0 ? logs.length : 0) / multiplier) + 1;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  live && len > size && setSize(len);

  return {
    logs,
    last,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    size,
    setSize,
  };
}

function useGetProjectLogs_k8s(
  filters = {},
  live = false,
  swr = true,
  queryInUrl = false,
  id
) {
  //states
  const [last, setLast] = useState(false);
  const [logs, setLogs] = useState(undefined);

  const multiplier = 15;
  //generate query string to be updated in the url
  // if (queryInUrl && swr) {
  // const urlFilterCopy = _.cloneDeep(filters);
  // //convert time to UTC
  // if (urlFilterCopy["fromTime"]) {
  // 	urlFilterCopy["fromTime"] = convertDateToUtc0(urlFilterCopy["fromTime"]);
  // }
  // if (urlFilterCopy["toTime"]) {
  // 	urlFilterCopy["toTime"] = convertDateToUtc0(urlFilterCopy["toTime"]);
  // }
  // //remove key to not be saved in url query string
  // delete urlFilterCopy["createdAt"];
  // const queryStringsForUrl = Object.entries(urlFilterCopy).map((obj) => obj[1] && obj[1] != "" ? Array.isArray(obj[1]) ? obj[1].map((val) => val && val != "" ? `${obj[0]}=${val}` : undefined) : `${obj[0]}=${obj[1]}` : undefined);
  // let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryStringsForUrl.flat().filter(x => typeof x === 'string' && x.length > 0).join('&');
  // console.log(id + ", HERE")
  // window.history.pushState({}, '', newUrl);
  // }
  //generated query string to be send to gateway removing timeline, fromTime, toTime
  const gatewayFilterCopy = _.cloneDeep(filters);
  delete gatewayFilterCopy["timeline"];
  delete gatewayFilterCopy["fromTime"];
  delete gatewayFilterCopy["toTime"];
  const queryStrings = Object.entries(gatewayFilterCopy).map((obj) =>
    obj[1] && obj[1] != ""
      ? Array.isArray(obj[1])
        ? obj[1].map((val) =>
            val && val != "" ? `${obj[0]}=${val}` : undefined
          )
        : `${obj[0]}=${obj[1]}`
      : undefined
  );
  const projectId = useGetActualProjectId();
  const getKey = (pageIndex, previousPageData) => {
    if (!swr) return null;
    if (!projectId) return null;
    if (previousPageData && !previousPageData.data) return null;
    if (pageIndex === 0)
      return () =>
        projectId && [
          `/api/projects/${projectId}/logs?` +
            queryStrings
              .flat()
              .filter((x) => typeof x === "string" && x.length > 0)
              .join("&") +
            `&limit=${multiplier}`,
          "useGetProjectLogs_k8s",
          "",
        ];
    return () =>
      projectId && [
        `/api/projects/${projectId}/logs?` +
          queryStrings
            .flat()
            .filter((x) => typeof x === "string" && x.length > 0)
            .join("&") +
          `&limit=${multiplier}` +
          `&offset=${pageIndex * multiplier}`,
        "useGetProjectLogs_k8s",
        "",
      ];
  };
  const { data, error, isValidating, size, setSize } = useSWRInfinite(
    getKey,
    swrFetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: live,
      revalidateAll: live,
      refreshInterval: live ? 5000 : 0,
    }
  );
  const { createLoader } = useSetLoader();
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data ? data[0] : undefined,
    error,
    isValidating,
    "gateway"
  );
  useEffect(() => {
    createLoader({
      id: "useGetProjectLogs",
      state: showLoader,
      location: "useGetProjectLogs",
    });
  }, [showLoader]);
  //concatenate logs received from gateway in different pages
  useEffect(() => {
    if (data) {
      const concatenatedLogs = { data: [] };
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(data) &&
        data.forEach((log) => {
          if (log.data.length > 0) {
            if (last) setLast(false);
            if (concatenatedLogs.data.length > 0) {
              concatenatedLogs.data = concatenatedLogs.data.concat(log.data);
            } else {
              concatenatedLogs.data = log.data;
            }
          } else {
            setLast(true);
          }
        });
      setLogs(concatenatedLogs.data);
    } else {
      setLogs(undefined);
    }
  }, [data]);

  //increment size in live mode
  const len =
    Math.trunc((logs && logs.length > 0 ? logs.length : 0) / multiplier) + 1;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  live && len > size && setSize(len);

  return {
    logs,
    last,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    size,
    setSize,
  };
}

function useGetHardwares() {
  const { data, error, isValidating } = useSWR(
    () => [`/api/hardwares`, "useGetHardwares"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const showLoader = (!error && !data) || isValidating;

  return {
    hardwares: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region JOBS related API
function useGetJobNodes(refreshInterval, projectId) {
  let actualProjectId = useGetActualProjectId();
  let projectIdToUse;
  if (projectId) {
    projectIdToUse = projectId;
  } else {
    projectIdToUse = actualProjectId;
  }
  const { data, error, isValidating } = useSWR(
    () =>
      projectIdToUse && [
        `/api/projects/${projectIdToUse}/jobs/nodes`,
        "useGetJobNodes",
      ],
    swrFetcher,
    {
      refreshInterval: refreshInterval,
      revalidateOnFocus: true,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    jobNodes: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region DATABASES related API

function useGetDatabases(prjId) {
  const actualNodesNumber = useGetActualNodesNumber(prjId);
  let projectId = useGetActualProjectId();
  if (prjId) {
    projectId = prjId;
  }
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      actualNodesNumber &&
      actualNodesNumber > 0 && [
        `/api/projects/${projectId}/databases`,
        "useGetDatabases",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    databases: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetDatabasesGateway() {
  //get acutal project info
  const projectInfo = useGetProjectInfo();
  const gatewayUrlHTTP = projectInfo.gatewayUrlHTTP;
  const connectionStringApiKey = projectInfo.connectionStringApiKey;
  //get actual number of nodes
  const actualNodesNumber = useGetActualNodesNumber();
  //read user company
  const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();
  const { data, error, isValidating } = useSWR(
    () =>
      actualNodesNumber &&
      actualNodesNumber > 0 &&
      connectionStringApiKey &&
      gatewayUrlHTTP &&
      isSqlitecloudCompanyUser && [
        `${gatewayUrlHTTP}/v1/databases`,
        "useGetDatabasesGateway",
        "",
        connectionStringApiKey,
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  // analyzeReturnData(data);
  // decodeURIParamenter(data?.data, ["name"]);
  const showLoader = (!error && !data) || isValidating;

  return {
    databases: data !== undefined ? data.data : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useDownloadDatabase() {
  //get acutal project info
  const projectInfo = useGetProjectInfo();
  const projectId = projectInfo.id;
  const gatewayUrlHTTP = projectInfo.gatewayUrlHTTP;
  const connectionStringApiKey = projectInfo.connectionStringApiKey;
  //get method to update snack notification
  const { createSnackNotification } = useSetSnackNotification();
  //get method to call editing api
  const { loading, error, editedData, mutatedData, mutatingData, editData } =
    useEditData();
  //handle downloading progress
  const [downloadQueue, setDownloadQueue] = useState(new Map());
  const downloadQueueRef = useRef();
  downloadQueueRef.current = downloadQueue;
  const handleProgress = (progressEvent, databaseName) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (downloadQueueRef.current.has(databaseName)) {
      const newDownloadQueue = deepCopyMap(downloadQueueRef.current);
      const dbItem = newDownloadQueue.get(databaseName);
      dbItem.progress = progress;
      newDownloadQueue.set(databaseName, dbItem);
      downloadQueueRef.current = newDownloadQueue;
      setDownloadQueue(newDownloadQueue);
    }
  };
  const downloadDb = async (
    databaseName,
    onDownloadProgress = undefined,
    tech = "axios"
  ) => {
    if (databaseName) {
      const opt = {
        tech: tech,
        method: "GET",
        endpoint: `${gatewayUrlHTTP}/v2/weblite/${databaseName}`,
        endpointCallLocation: "useDownloadDatabase",
        auth: connectionStringApiKey,
        onDownloadProgress: (progressEvent) => {
          handleProgress(progressEvent, databaseName);
          if (onDownloadProgress) {
            onDownloadProgress(progressEvent);
          }
        },
        isBlob: false,
      };
      //save new download in the download queue
      const newDownloadQueue = deepCopyMap(downloadQueueRef.current);
      newDownloadQueue.set(databaseName, {
        projectId: projectId,
        dowloadingDb: true,
        progress: 0,
      });
      downloadQueueRef.current = newDownloadQueue;
      setDownloadQueue(newDownloadQueue);
      await editData(opt);
    }
  };
  useEffect(() => {
    if (editedData) {
      const requestUrl = editedData.config.url.split("/");
      const databaseName = requestUrl[requestUrl.length - 1];
      var a = document.createElement("a");
      var url = URL.createObjectURL(editedData.data);
      a.href = url;
      a.download = databaseName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      const NewDatabase = ({ databaseName }) => {
        return (
          <Typography variant="14px-med">
            Database {databaseName} download completed
          </Typography>
        );
      };
      const NewDatabaseComponent = <NewDatabase databaseName={databaseName} />;
      createSnackNotification({
        type: "info-2",
        component: NewDatabaseComponent,
      });
      //remove download from queue
      if (downloadQueueRef.current.has(databaseName)) {
        const newDownloadQueue = deepCopyMap(downloadQueueRef.current);
        newDownloadQueue.delete(databaseName);
        downloadQueueRef.current = newDownloadQueue;
        setDownloadQueue(newDownloadQueue);
      }
    }
  }, [editedData]);
  useEffect(() => {
    if (error && error.info && error.info.config && error.info.config.url) {
      const requestUrl = error.info.config.url.split("/");
      const databaseName = requestUrl[requestUrl.length - 1];
      //remove download from queue
      if (downloadQueueRef.current.has(databaseName)) {
        const newDownloadQueue = deepCopyMap(downloadQueueRef.current);
        newDownloadQueue.delete(databaseName);
        downloadQueueRef.current = newDownloadQueue;
        setDownloadQueue(newDownloadQueue);
      }
    }
  }, [error]);
  return {
    loading,
    error,
    editedData,
    mutatedData,
    mutatingData,
    downloadQueue,
    downloadDb,
  };
}

function useUploadDatabase() {
  //get theme
  const theme = useTheme();
  //get acutal project info
  const projectInfo = useGetProjectInfo();
  const projectId = projectInfo.id;
  const gatewayUrlHTTP = projectInfo.gatewayUrlHTTP;
  const connectionStringApiKey = projectInfo.connectionStringApiKey;
  //get method to update snack notification
  const { createSnackNotification } = useSetSnackNotification();
  //get method to call editing api
  const { loading, error, editedData, mutatedData, mutatingData, editData } =
    useEditData();
  //handle uploading progress
  const [uploadQueue, setUploadQueue] = useState(new Map());
  const uploadingRef = useRef();
  uploadingRef.current = uploadQueue;
  const handleProgress = (progressEvent, databaseName) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (uploadingRef.current.has(databaseName)) {
      const newUploadQueue = deepCopyMap(uploadingRef.current);
      const dbItem = newUploadQueue.get(databaseName);
      dbItem.progress = progress;
      newUploadQueue.set(databaseName, dbItem);
      uploadingRef.current = newUploadQueue;
      setUploadQueue(newUploadQueue);
    }
  };
  const uploadDb = async (databaseToUpload, onUploadProgress = undefined) => {
    if (databaseToUpload) {
      const databaseName = databaseToUpload.name;
      const key = databaseToUpload.key;
      const overwrite = databaseToUpload.overwrite;
      const opt = {
        tech: "axios",
        method: overwrite ? "PATCH" : "POST",
        endpoint: `${gatewayUrlHTTP}/v2/weblite/${databaseName}?key=${key}`,
        endpointCallLocation: "useUploadDatabase",
        auth: connectionStringApiKey,
        body: databaseToUpload.payload,
        mutateApis: [
          [`/api/projects/${projectId}/databases`, "useGetDatabases"],
          [`/api/projects/${projectId}/databases`, "useGetProjectDatabases"],
        ],
        onUploadProgress: (progressEvent) => {
          handleProgress(progressEvent, databaseName);
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        },
      };
      //save new upload in the download queue
      const newUploadQueue = deepCopyMap(uploadingRef.current);
      newUploadQueue.set(databaseName, {
        projectId: projectId,
        uploadingDb: true,
        progress: 0,
      });
      uploadingRef.current = newUploadQueue;
      setUploadQueue(newUploadQueue);
      await editData(opt);
    }
  };
  useEffect(() => {
    if (editedData) {
      const databaseName = editedData.data.name;
      let projectId;
      if (uploadingRef.current.has(databaseName)) {
        projectId = uploadingRef.current.get(databaseName).projectId;
      }
      // const NewDatabase = ({ callback }) => {
      //   //get actual url
      //   let url = "";
      //   let dbUrl = "";
      //   if (typeof window !== "undefined") {
      //     url = new URL(window.location.href);
      //     const path = `/projects/${projectId}/console?databaseName=${databaseName}`;
      //     const base = `${url.protocol}//${url.hostname}${url.hostname.includes("local") ? ":3000" : ""}`;
      //     dbUrl = `${base}${path}`;
      //   }
      //   return (
      //     <div>
      //       <Typography variant="14px-med">
      //         You just upload your database {databaseName}.
      //       </Typography>
      //       <br />
      //       {projectId && url && (
      //         <NextLink href={dbUrl} legacyBehavior>
      //           <a
      //             onClick={callback}
      //             style={{ cursor: "pointer" }}
      //             className="unstyled-link"
      //           >
      //             <Typography
      //               variant="14px-reg"
      //               sx={{
      //                 color: theme.palette.secondary.main,
      //                 textDecoration: "underline",
      //               }}
      //             >
      //               Explore your database
      //             </Typography>
      //           </a>
      //         </NextLink>
      //       )}
      //     </div>
      //   );
      // };
      // const NewDatabaseComponent = <NewDatabase />;
      // createSnackNotification({
      //   type: "info-2",
      //   component: NewDatabaseComponent,
      // });
      //remove upload from queue
      if (uploadingRef.current.has(databaseName)) {
        const newUploadQueue = deepCopyMap(uploadingRef.current);
        newUploadQueue.delete(databaseName);
        uploadingRef.current = newUploadQueue;
        setUploadQueue(newUploadQueue);
      }
    }
  }, [editedData]);
  useEffect(() => {
    if (error && error.info && error.info.config && error.info.config.url) {
      const requestUrl = error.info.config.url.split("/");
      const databaseName = requestUrl[requestUrl.length - 1].split("?")[0];

      //remove upload from queue
      if (uploadingRef.current.has(databaseName)) {
        const newUploadQueue = deepCopyMap(uploadingRef.current);
        newUploadQueue.delete(databaseName);
        uploadingRef.current = newUploadQueue;
        setUploadQueue(newUploadQueue);
      }
    }
  }, [error]);
  return {
    loading,
    error,
    editedData,
    mutatedData,
    mutatingData,
    uploadQueue,
    uploadDb,
  };
}

function useGetDatabasesTotSize() {
  const { databases } = useGetDatabases();
  const [totSize, setTotSize] = useState(null);
  useEffect(() => {
    if (databases) {
      let sum = 0;
      for (let obj of databases) {
        sum += obj.size;
      }
      setTotSize(sum);
    }
  }, [databases]);
  return totSize;
}

function useGetDatabaseTables(databaseName) {
  const { databases } = useGetDatabases();
  const testResult = databaseNameExist(databaseName, databases);
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      databaseName &&
      testResult && [
        `/api/projects/${projectId}/databases/${databaseName}/tables`,
        "useGetDatabaseTables",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    tables: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetDatabaseTablesMetadata(databaseName) {
  const { databases } = useGetDatabases();
  const testResult = databaseNameExist(databaseName, databases);
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      databaseName &&
      testResult && [
        `/api/projects/${projectId}/databases/${databaseName}/metadata`,
        "useGetDatabaseTablesMetadata",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  analyzeReturnData(data);
  const showLoader = (!error && !data) || isValidating;

  return {
    tablesMetadata: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetDatabaseTableMetadata(databaseName, tableName) {
  const { databases } = useGetDatabases();
  const { tables } = useGetDatabaseTables(databaseName);
  const testResultDatabase = databaseNameExist(databaseName, databases);
  const testResultTable = tableNameExist(tableName, tables);
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      databaseName &&
      testResultDatabase &&
      testResultTable && [
        `/api/projects/${projectId}/databases/${databaseName}/table/${tableName}/metadata`,
        "useGetDatabaseTableMetadata",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    tableMetadata: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region ANALYZER related API
function useGetAnalyzerGroups(nodeID) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeID !== null && [
        `/api/projects/${projectId}/nodes/${nodeID}/analyzer`,
        "useGetAnalyzerGroups",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  analyzeReturnData(data);
  const showLoader = (!error && !data) || isValidating;

  return {
    analyzerGroups: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetAnalyzerGroupDetails(nodeID, groupID) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeID !== null && [
        `/api/projects/${projectId}/nodes/${nodeID}/analyzer/groups/${groupID}`,
        "useGetAnalyzerGroupDetails",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    analyzerGroupDetails: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetAnalyzerGroupPlan(nodeID, groupID) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeID !== null && [
        `/api/projects/${projectId}/nodes/${nodeID}/analyzer/groups/${groupID}/plan`,
        "useGetAnalyzerGroupPlan",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    analyzerGroupPlan: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetAnalyzerGroupSuggest(nodeID, groupID) {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId &&
      nodeID !== null && [
        `/api/projects/${projectId}/nodes/${nodeID}/analyzer/groups/${groupID}/suggest`,
        "useGetAnalyzerGroupSuggest",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    analyzerGroupSuggest: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
  };
}

// #endregion

// #region BACKUPS related API
function useGetDatabasesWithBackups() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `/api/projects/${projectId}/backups`,
        "useGetDatabasesWithBackups",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    databases: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetBackupsSettings() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        `/api/projects/${projectId}/backups/settings`,
        "useGetBackupsSettings",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  analyzeReturnData(data);
  const showLoader = (!error && !data) || isValidating;

  return {
    settings: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetDabataseBackupSetting(databaseName) {
  const { settings } = useGetBackupsSettings();
  let databaseBackupSetting = undefined;
  if (settings && databaseName) {
    settings.forEach((setting) => {
      if (setting.name === databaseName) {
        databaseBackupSetting = setting;
      }
    });
  }
  return databaseBackupSetting;
}

// #endregion

// #region SETTINGS related API
function useGetSettings() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [`/api/projects/${projectId}/settings`, "useGetSettings"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    settings: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetAnalyzerSettings() {
  const { settings, error, showLoader } = useGetSettings();
  const [analyzerState, setAnalyzerState] = useState(false);
  const [analyzerThreshold, setAnalyzerThreshold] = useState(null);
  useEffect(() => {
    if (settings && !error && !showLoader) {
      settings.forEach((setting) => {
        if (setting.key == "query_analyzer_enabled") {
          setAnalyzerState(setting.value == "1" ? true : false);
        }
        if (setting.key == "query_analyzer_threshold") {
          setAnalyzerThreshold(setting.value);
        }
      });
    }
  }, [settings, error, showLoader]);

  return { analyzerState, analyzerThreshold, error, showLoader };
}

function useSetSetting(opt = {}) {
  //get actual project id
  const projectId = useGetActualProjectId();
  //get method to call editing api
  const { loading, error, editedData, mutatedData, mutatingData, editData } =
    useEditData(opt);
  const setSetting = async (setting) => {
    const body = {
      value: setting.value,
    };
    const opt = {
      method: "POST",
      endpoint: `/api/projects/${projectId}/setting/${setting.key}`,
      endpointCallLocation: "useApi.js",
      body: body,
      mutateApis: [[`/api/projects/${projectId}/settings`, "useGetSettings"]],
    };
    await editData(opt);
  };
  return { loading, error, editedData, mutatedData, mutatingData, setSetting };
}

// #endregion

// #region COMMANDS related API
function useGetCommands() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [`/api/projects/${projectId}/commands`, "useGetCommands"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    commands: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region PLUGINS related API
function useGetPlugins() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () => projectId && [`/api/projects/${projectId}/plugins`, "useGetPlugins"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    plugins: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region IPS related API
function useGetIps() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () => projectId && [`/api/projects/${projectId}/ips`, "useGetIps"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    ips: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region ENV related API
function useGetProjectEnv() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () => projectId && [`/api/projects/${projectId}/env`, "useGetProjectEnv"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    projectEnv: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region WEBHOOKS RELATED
function useGetWebhooks() {
  //get connection string to node
  const { gatewayUrlHTTP, connectionStringApiKey } = useGetProjectInfo();
  //read webhook list
  const { data, error, isValidating } = useSWR(
    () =>
      connectionStringApiKey &&
      gatewayUrlHTTP && [
        `${gatewayUrlHTTP}/v2/webhooks`,
        "useGetWebhooks",
        "",
        connectionStringApiKey,
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "gateway"
  );

  return {
    webhooks: data !== undefined ? data.data : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useSetWebhook(opt = {}) {
  //get connection string to node
  const { gatewayUrlHTTP, connectionStringApiKey } = useGetProjectInfo();
  //get method to call editing api
  const { loading, mutatingData, mutatedData, editedData, error, editData } =
    useEditData(opt);
  /**
   * Async method to create or edit webhook
   * @param {Object} webhook = webhook details, if the id key is defined it will edit the webhook, otherwise it will create a new one
   * @param {Boolean} toDelete = if it's defined as true it will delete the webhook
   */
  const createEditWebhook = async (
    webhook,
    toDelete = false,
    enableMutate = true
  ) => {
    let body = webhook;

    const opt = {
      method: toDelete ? "DELETE" : "PATCH",
      endpoint: `${gatewayUrlHTTP}/v2/webhooks/${webhook.id ? webhook.id : ""}`,
      endpointCallLocation: "useApi.js",
      body: body,
      auth: connectionStringApiKey,
      mutateApis: enableMutate
        ? [
            [
              `${gatewayUrlHTTP}/v2/webhooks`,
              "useGetWebhooks",
              "",
              connectionStringApiKey,
            ],
          ]
        : [],
    };
    await editData(opt);
  };

  return {
    loading,
    error,
    editedData,
    mutatedData,
    mutatingData,
    createEditWebhook,
  };
}

// #endregion

// #region OPEN API
function useGetOpenApiManifest() {
  //get project info
  const {
    gatewayDeployment,
    gatewayUrlHTTP,
    deployment,
    connectionStringApiKey,
  } = useGetProjectInfo();
  //creation state
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  useSetError(error);
  //get openManifestApi
  const [openManifestApi, setOpenManifestApi] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  useEffect(() => {
    const getManifest = async () => {
      const opt = {
        method: "GET",
        endpoint: `${gatewayUrlHTTP}/v2/openapi.json`,
        endpointCallLocation: "useGetOpenApiManifest",
      };
      setLoading(true);
      try {
        const data = await fetchApiRoute(opt);
        data.servers[0].url = gatewayUrlHTTP;
        data.servers[0].variables.node.default = gatewayDeployment;
        setVersion(data.info.version);
        setOpenManifestApi(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (gatewayUrlHTTP && deployment && connectionStringApiKey) {
      getManifest();
    }
  }, [gatewayUrlHTTP, deployment, connectionStringApiKey]);
  return {
    loading,
    error,
    connectionStringApiKey,
    gatewayUrlHTTP,
    openManifestApi,
    version,
  };
}

function useGetOpenApiManifest_v2() {
  //get project info
  const {
    gatewayDeployment,
    gatewayUrlHTTP,
    deployment,
    connectionStringApiKey,
  } = useGetProjectInfo();
  //openManifestApi states
  const [openManifestApi, setOpenManifestApi] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  //get openManifestApi
  const { data, error, isValidating } = useSWR(
    () =>
      connectionStringApiKey &&
      deployment &&
      gatewayUrlHTTP && [
        `${gatewayUrlHTTP}/v2/openapi.json`,
        "useGetOpenApiManifest_v2",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;
  useEffect(() => {
    if (!error && !isValidating && data) {
      data.servers[0].url = gatewayUrlHTTP;
      data.servers[0].variables.node.default = gatewayDeployment;
      setVersion(data.info.version);
      setOpenManifestApi(data);
    }
  }, [data, error, isValidating]);
  return {
    showLoader,
    isValidating,
    isError: error,
    connectionStringApiKey,
    gatewayUrlHTTP,
    openManifestApi,
    version,
  };
}
// #endregion

// #region OPEN API
/**
 * opt = {
 *  sql: true
 * }
 * selectedTypes = 'sql' | 'javascript' | 'typescript' | 'python'
 */
function useGetFunctionsGateway(
  selectedTypes = ["sql", "javascript", "typescript", "python"]
) {
  //get user info
  const { user: userInfo } = useGetUser();
  //define test slug run funciont based on user id
  const testSlugBasePath = `_test-`;
  const testSlug = `${testSlugBasePath}${userInfo?.id}`;
  //get acutal project info
  const projectInfo = useGetProjectInfo();
  const gatewayUrlHTTP = projectInfo.gatewayUrlHTTP;
  const connectionStringApiKey = projectInfo.connectionStringApiKey;
  //read user company
  const { data, error, isValidating } = useSWR(
    () =>
      connectionStringApiKey &&
      gatewayUrlHTTP && [
        `${gatewayUrlHTTP}/v2/functions`,
        "useGetFunctionsGateway",
        "",
        connectionStringApiKey,
      ],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "gateway"
  );
  const [functions, setFunctions] = useState(null);
  const [testFn, setTestFn] = useState(null);
  useEffect(() => {
    if (hasData) {
      let testFn = data.data.filter(function (item) {
        return item.slug == testSlug;
      });
      if (testFn && Array.isArray(testFn)) {
        testFn = testFn[0];
      }
      let selectedFns = data.data.filter((item) =>
        selectedTypes.includes(item.type)
      );
      selectedFns = selectedFns.filter(function (item) {
        if (!item.slug.includes(testSlugBasePath)) {
          return item;
        }
      });
      const groupedFns = {};
      const groupKey = "type";
      const labelKey = "slug";
      //group items by groupKey
      selectedFns.forEach((item) => {
        if (!groupedFns[item[groupKey]]) {
          groupedFns[item[groupKey]] = [];
        }
        groupedFns[item[groupKey]].push(item);
      });
      //sort items within each group alphabetically by labelKey
      for (const groupKey in groupedFns) {
        groupedFns[groupKey].sort((a, b) =>
          a[labelKey].localeCompare(b[labelKey])
        );
      }
      //concatenate the arrays within groupedFns into a single array
      const concatenatedArray = Object.values(groupedFns).reduce(
        (acc, val) => acc.concat(val),
        []
      );
      setFunctions(concatenatedArray);
      setTestFn(testFn);
    } else if (emptyData) {
      setFunctions([]);
    }
  }, [emptyData, hasData, data, testSlug]);

  return {
    functions: data !== undefined ? functions : data,
    testSlug: testSlug,
    testFn: testFn,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData:
      hasData && functions && Array.isArray(functions) && functions.length > 0,
    emptyData:
      emptyData ||
      (functions && Array.isArray(functions) && functions.length === 0),
  };
}

function useSetFunctionGateway(opt = {}) {
  //get acutal project info
  const { gatewayUrlHTTP, connectionStringApiKey } = useGetProjectInfo();
  //get method to call editing api
  const { loading, mutatingData, mutatedData, editedData, error, editData } =
    useEditData(opt);
  /**
   * Async method to set function
   * @param {*} newFn = new function (create)
   * @param {*} oldFn = old function (edit)
   */
  const createEditFunction = async (
    newFn,
    oldFn = undefined,
    enableMutate = true
  ) => {
    let body = {};

    body.type = newFn.type ? newFn.type : oldFn.type;
    body.code = newFn.code ? newFn.code : oldFn.code;
    if (newFn && newFn.options) {
      body.options = newFn.options;
    } else if (oldFn && oldFn.option) {
      body.options = oldFn.options;
    }

    if (oldFn) {
      body.id = oldFn.id;
      body.slug = newFn.slug ? newFn.slug : oldFn.slug;
    }

    const opt = {
      method: "PATCH",
      endpoint: `${gatewayUrlHTTP}/v2/functions/${oldFn ? oldFn.slug : newFn.slug}`,
      endpointCallLocation: "useApi.js",
      body: body,
      auth: connectionStringApiKey,
      mutateApis: enableMutate
        ? [
            [
              `${gatewayUrlHTTP}/v2/functions`,
              "useGetFunctionsGateway",
              "",
              connectionStringApiKey,
            ],
          ]
        : [],
    };
    await editData(opt);
  };

  return {
    loading,
    error,
    editedData,
    mutatedData,
    mutatingData,
    createEditFunction,
  };
}

function useExecuteFunctionGateway(opt = {}) {
  //get acutal project info
  const { gatewayUrlHTTP, adminApiKey, connectionStringApiKey } =
    useGetProjectInfo();
  //get method to call editing api
  const { loading, mutatingData, mutatedData, editedData, error, editData } =
    useEditData(opt);

  const executeFunction = async (slug, body, queryString, options) => {
    const addDefatulAuth =
      !options || !("apikey" in options) || (options && !options.apikey);
    const opt = {
      method: body ? "POST" : "GET",
      endpoint: `${gatewayUrlHTTP}/v2/functions/${slug}?${queryString}`,
      endpointCallLocation: "useApi.js",
      body: body,
      auth: addDefatulAuth ? connectionStringApiKey : "",
    };
    await editData(opt);
  };
  return {
    loading,
    error,
    editedData,
    mutatedData,
    mutatingData,
    executeFunction,
  };
}
// #region

// #region WEBLITE RELATED
function useGetWeblite() {
  const projectId = useGetActualProjectId();
  const { data, error, isValidating } = useSWR(
    () => projectId && [`/api/projects/${projectId}/weblite`, "useGetWeblite"],
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  analyzeReturnData(data);
  const showLoader = (!error && !data) || isValidating;

  return {
    weblite: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region CREATE DATA
function useEditData(opt = {}) {
  //analyze opt
  const startCallback = opt.startCallback ?? null;
  const errorCallback = opt.errorCallback ?? null;
  const editedCallback = opt.editedCallback ?? null;
  const mutatedCallback = opt.mutatedCallback ?? null;
  //get mutate swr method
  const { mutate } = useSWRConfig();
  //creation state
  const [loading, setLoading] = useState(null);

  const [error, setError] = useState(null);
  useSetError(opt.stopGlobalError ? null : error);
  const [editedData, setEditedData] = useState(null);
  const [mutatedData, setMutatedData] = useState(null);
  const [mutatingData, setMutatingData] = useState(false);
  let errorCatch = false;
  //reset state before new edit
  const resetStates = () => {
    setEditedData(null);
    setMutatedData(null);
    setError(null);
  };
  const editData = async (opt) => {
    resetStates();
    if (startCallback) {
      startCallback();
    }
    const { endpointCallLocation, mutateApis } = opt;
    const loadingId = generateRandomId();
    setLoading(true);
    if (mutateApis && Array.isArray(mutateApis) && mutateApis.length > 0) {
      setMutatingData(true);
    }
    setError(null);
    try {
      const data = await fetchApiRoute(opt);
      if (editedCallback) {
        editedCallback(data);
      }
      setEditedData(data);
    } catch (error) {
      setError(error);
      if (errorCallback) {
        errorCallback(error);
      }
      errorCatch = true;
    } finally {
      setLoading(false);
      if (mutateApis && Array.isArray(mutateApis)) {
        if (!errorCatch) {
          let arrayMutatedData = [];
          for (let i = 0; i < mutateApis.length; i++) {
            const awaitMutated = await mutate(mutateApis[i]);
            arrayMutatedData.push(awaitMutated);
          }
          if (mutatedCallback) {
            mutatedCallback();
            setMutatedData(arrayMutatedData);
            setMutatingData(false);
          }
        } else {
          setMutatingData(false);
        }
      }
    }
  };
  return { loading, error, editedData, mutatedData, mutatingData, editData };
}

// #endregion

export {
  useDownloadDatabase,
  useEditData,
  useExecuteFunctionGateway,
  useGetActualAndPrevProjectId,
  useGetActualDatabaseName,
  useGetActualNodeId,
  useGetActualNodesNumber,
  useGetActualPathname,
  useGetActualProjectId,
  useGetActualQuery,
  useGetActualTableName,
  useGetAnalyzerGroupDetails,
  useGetAnalyzerGroupPlan,
  useGetAnalyzerGroups,
  useGetAnalyzerGroupSuggest,
  useGetAnalyzerSettings,
  useGetBackupsSettings,
  useGetBuildId,
  useGetCommands,
  useGetDabataseBackupSetting,
  useGetDatabases,
  useGetDatabasesGateway,
  useGetDatabasesTotSize,
  useGetDatabasesWithBackups,
  useGetDatabaseTableMetadata,
  useGetDatabaseTables,
  useGetDatabaseTablesMetadata,
  useGetFunctionsGateway,
  useGetHardwares,
  useGetIps,
  useGetJobNodes,
  useGetNode,
  useGetNodeConnections,
  useGetNodeLogs,
  useGetNodes,
  useGetNodeStat,
  useGetNodeStatGateway,
  useGetOpenApiManifest,
  useGetOpenApiManifest_v2,
  useGetPlugins,
  useGetProject,
  useGetProjectEnv,
  useGetProjectInfo,
  useGetProjectLogs,
  useGetProjectLogs_k8s,
  useGetProjectLogsInfo,
  useGetProjectPrivileges,
  useGetProjectRegions,
  useGetProjectRoles,
  useGetProjects,
  useGetSettings,
  useGetSqlitecloudCompanyUser,
  useGetUser,
  useGetUserAccountProfile,
  useGetWebhooks,
  useGetWeblite,
  useSetFunctionGateway,
  useSetSetting,
  useSetWebhook,
  useUploadDatabase,
};
