import { useGetUserSession } from "@custom-hooks/user";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import {
  PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS,
  SQLITE_CLOUD_ORGANIZATION_ID,
} from "@lib/billing/constants";
import Stripe from "stripe";
import { useGetCurrentOrganizationId } from "./useGetCurrentOrganizationId";

/**
 * Custom hook to retrieve subscription details and status from the user's session.
 *
 * @returns {Object} - An object containing the following:
 *   - `subscriptionStatus`: The current status of the subscription (e.g., "active", "past_due").
 *   - `hasSubscription`: A boolean indicating if the user has a valid subscription
 *     (true for "active" or "past_due", false otherwise).
 *   - `subscriptionStatusAlertProps`: Alert properties for displaying messages related to
 *     the subscription status, such as actions or warnings.
 */
export const useGetSubscriptionDetails = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  const subscription = organizationId
    ? session?.authorizations?.organizations[organizationId]?.attributes
        ?.subscription
    : undefined;

  const subscriptionStatus = subscription
    ? (subscription.status as Stripe.Subscription.Status)
    : undefined;

  const statusAlertProps: SubscriptionStatusAlertProps | undefined = (() => {
    switch (subscriptionStatus) {
      case "incomplete":
        return {
          title: "Update Your Payment Method",
          description:
            "Your subscription payment could not be processed. Please update your payment method.",
          actionButtonText: "Edit payment method",
        };
      case "past_due":
        const currentPeriodStart = subscription
          ? subscription.current_period_start
          : undefined;

        if (currentPeriodStart) {
          const currentPeriodStartDate = new Date(currentPeriodStart * 1000);
          const date = new Date();

          const diffInMs = date.getTime() - currentPeriodStartDate.getTime();
          const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

          if (PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS > diffInDays) {
            return {
              title: "Update Your Payment Method",
              description: `Your subscription payment is overdue. Please update your payment method to avoid cancellation in ${PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS - diffInDays} day(s).`,
              actionButtonText: "Edit payment method",
            };
          }
        } else {
          return {
            title: "Update Your Payment Method",
            description: `Your subscription payment is overdue. Please update your payment method.`,
            actionButtonText: "Edit payment method",
          };
        }
      case "unpaid":
        return {
          title: "Subscription Paused Due to Unpaid Invoice",
          description: `Your subscription has been paused because your renewal payment was not completed in ${PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS}-day grace period. To regain full access to features, please complete your payment.`,
          actionButtonText: "Go to invoices",
        };
      case "active":
        if (subscription) {
          if (subscription?.pending_update) {
            return {
              title: "Pay Your Outstanding Invoice",
              description:
                "Your subscription is active, but some features are restricted due to an unpaid invoice. Complete your payment to restore full functionality.",
              actionButtonText: "Go to invoices",
            };
          }
        }
    }

    return undefined;
  })();

  return {
    subscriptionStatus,
    hasSubscription: availableSession
      ? subscriptionStatus
        ? ["trialing", "past_due", "unpaid", "active"].includes(
            subscriptionStatus
          )
        : false
      : undefined,
    subscriptionStatusAlertProps:
      organizationId === SQLITE_CLOUD_ORGANIZATION_ID
        ? undefined
        : statusAlertProps,
  };
};
