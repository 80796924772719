//
// getNodeLocation.ts
//

import { Node } from "@data-types/nodes-types";

/**
 * Extracts the location from a node's region string.
 *
 * - If "K8s" is present in the region string, it will be removed.
 * - If the region contains a location in parentheses (e.g., "Europe (Frankfurt)"), the part inside parentheses will be returned.
 * - If no parentheses are present, the region string (without "K8s") will be returned.
 * - If the region is undefined or empty, "unknown" will be returned.
 *
 * @param node - The node object containing the region property.
 * @returns The extracted location or "unknown" if the region is not available.
 */
export function getNodeLocation(node?: Node): string {
  if (!node?.region) {
    return "unknown"; // Return "unknown" if no region is provided
  }

  // Remove "K8s" if present and trim the result
  const region = node.region.replace("K8s", "").trim();

  // Match the location within parentheses
  const regex = /\((.*?)\)/;
  const matches = region.match(regex);

  if (matches) {
    return matches[1]; // Return the location inside parentheses
  }

  // Return the cleaned region if no parentheses are found
  return region || "unknown";
}
