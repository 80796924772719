import { useGetCurrentOrganizationId } from "@custom-hooks/billing";
import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetUserSession } from "@custom-hooks/user";
import {
  AddonActionNames,
  HandlingUpdateSubscriptionFlow,
  UpdateSubscriptionSuccessResponse,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { useCallback } from "react";

/**
 * Custom hook to manage the subscription update process.
 *
 * @param {EditDataOpt<UpdateSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `updateSubscription` (function): A function to initiate the subscription update process.
 *       - Parameters:
 *         - `newAddon` (AddonItem<AddonLookupKey>): The new add-on to be added to the subscription.
 *       - Returns:
 *         - `Promise<UpdateSubscriptionSuccessResponse>`: The result of the subscription update process.
 *   - `isLoading` (boolean): Indicates whether the update process or associated mutation is in progress.
 *   - `error` (ClientError | null): The error returned by the useEditData_v2, if any.
 */
export const useUpdateSubscription = (
  options: EditDataOpt<UpdateSubscriptionSuccessResponse>
) => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const { email = "", userId = "" } = availableSession
    ? {
        userId: session?.userId,
        email:
          organizationId &&
          session?.authorizations?.organizations[organizationId]?.ownerEmail,
      }
    : {};

  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<UpdateSubscriptionSuccessResponse>({
      ...options,
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const updateSubscription = useCallback(
    async ({ newAddon }: Pick<HandlingUpdateSubscriptionFlow, "newAddon">) => {
      const projectId =
        newAddon.action.name === AddonActionNames.UPGRADE
          ? newAddon.action.data.projectId
          : undefined;

      const updateSubscriptionResponse = await editData({
        method: "PATCH",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useUpdateSubscription.js",
        body: {
          organizationId,
          email,
          userId,
          newAddon,
          successUrl: process.env.NEXT_PUBLIC_BILLING_SUCCESS_URL,
          cancelUrl: process.env.NEXT_PUBLIC_BILLING_CANCEL_URL,
        } as HandlingUpdateSubscriptionFlow,
        mutateApis: [
          ["/api/projects", "useGetUserProjects"],
          ["/api/projects", "useGetProjects"],
          ["/api/auth/session", "useGetUserSession"],
          ...(projectId
            ? [[`/api/projects/${projectId}/jobs/nodes`, "useGetProjectJobs"]]
            : []),
        ],
      });
      return updateSubscriptionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    updateSubscription,
    isLoading: isLoading || isMutatingData,
    error,
  };
};
