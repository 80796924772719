//
// iso-utils.ts - isomorphic utilities usable both client and server side
//

import { format } from "date-fns";
import { throwServerError } from "./server-side";

/**
 * Logs a message with a timestamped prefix to the console.
 *
 * @param {string} msg - The message to log.
 */
export function logThis(msg: string): void {
  // Generate a timestamp prefix in the format "yyyy/MM/dd HH:mm:ss.SSS"
  const prefix = format(new Date(), "yyyy/MM/dd HH:mm:ss.SSS");

  // Log the prefixed message to the console with added markers for emphasis
  console.log("!!!!!!!!! " + prefix + " - " + msg);
}

/**
 * Checks if an object has no enumerable properties.
 *
 * @param {Record<string, unknown>} obj - The object to check for properties.
 * @returns {boolean} Returns `true` if the object is empty, otherwise `false`.
 */
export function isEmpty(obj: Record<string, unknown>): boolean {
  // Iterate over the object's keys
  for (const key in obj) {
    // Check if the object has the key as its own property
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false; // Object is not empty
    }
  }
  return true; // Object is empty
}

/**
 * Normalizes an email by ensuring lowercase characters,
 * removing extra spaces, and removing any extraneous
 * characters after a comma in the domain part.
 *
 * @param email - The email string to normalize.
 * @returns A normalized email in the format of `local@domain`.
 * @throws Error if the email is missing from the request body.
 */
export function emailNormalizer(email?: string) {
  // Check if the email is provided; throw an error if missing
  if (!email) throw new Error("Missing email from request body.");

  // Split the email string into local and domain parts using "@" as the delimiter
  // Convert email to lowercase and trim any leading or trailing spaces
  let [local, domain] = email.toLowerCase().trim().split("@");

  // The domain part may include a comma; if present, only take the part before the comma
  domain = domain.split(",")[0];

  // Return the normalized email in the format of `local@domain`
  return `${local}@${domain}`;
}

/**
 * Generates initials from a given name.
 *
 * @param {string} name - The full name to generate initials from.
 * @returns {string} - The initials of the name. If the name consists of multiple words,
 * it returns the first letter of the first two non-empty words as initials. If the name has only
 * one word, it returns the first two letters of that word as initials, both in uppercase.
 */
export function getInitials(name: string): string {
  if (!name || typeof name !== "string") {
    return ""; // Return empty string for invalid input
  }

  // Remove extra spaces and split the name into non-empty words
  const words = name.trim().split(/\s+/).filter(Boolean);

  if (words.length > 1) {
    // Take the first letter of the first two non-empty words
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
  }

  if (words.length === 1 && words[0].length > 1) {
    // Take the first two letters of the single word
    return `${words[0][0].toUpperCase()}${words[0][1].toUpperCase()}`;
  }

  // If there's only one letter or no valid input, return it in uppercase
  return words[0]?.[0]?.toUpperCase() || "";
}

/**
 * Gets the UTC offset for a specified date or the current date if none is provided.
 *
 * @param {Date | undefined} referenceDate - The date to calculate the UTC offset for (optional).
 * @returns {string} The UTC offset formatted as "UTC±HH:MM".
 */
export function getUtcOffset(referenceDate?: Date): string {
  // Use the provided date or default to the current date
  const date = referenceDate || new Date();

  // Get the time zone offset in minutes (positive if behind UTC, negative if ahead)
  const timezoneOffsetInMinutes = date.getTimezoneOffset();

  // Calculate the offset hours and minutes as absolute values
  const offsetHours = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
  const offsetMinutes = Math.abs(timezoneOffsetInMinutes) % 60;

  // Determine the sign based on whether the timezone is behind (-) or ahead (+) of UTC
  const sign = timezoneOffsetInMinutes > 0 ? "-" : "+";

  // Format the UTC offset as a string in the format "UTC±HH:MM"
  const utcDifference = `UTC${sign}${String(offsetHours).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

  // Return the formatted UTC offset
  return utcDifference;
}

/**
 * Converts a given date to UTC (offset 0) and formats it as "yyyy-MM-dd HH:mm:ss".
 *
 * @param {Date | string | number} inputDate - The date to convert to UTC. Can be a Date object, ISO string, or timestamp.
 * @returns {string} The formatted date string in UTC.
 */
export function convertDateToUtc0(inputDate: Date | string | number): string {
  // Convert input to a Date object
  const date = new Date(inputDate);

  // Adjust date to UTC by adding the timezone offset in milliseconds
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Format the UTC date as "yyyy-MM-dd HH:mm:ss"
  const outputString = format(utcDate, "yyyy-MM-dd HH:mm:ss");

  // Return the formatted date string
  return outputString;
}

/**
 * Converts a UTC date to the user's local time zone and formats it as specified.
 *
 * @param {Date | string | number} inputDate - The UTC date to convert to local time.
 * @param {string} dateFormat - The format to output the date in (default: "yyyy-MM-dd HH:mm:ss").
 * @param {boolean} appendUtc - Whether to append the UTC offset to the formatted string (default: `false`).
 * @returns {string} The formatted local date string, optionally with UTC offset.
 */
export function convertUtc0ToLocalTimeZone(
  inputDate: Date | string | number,
  dateFormat: string = "yyyy-MM-dd HH:mm:ss",
  appendUtc: boolean = false
): string {
  // Convert the input to a Date object
  const date = new Date(inputDate);

  // Adjust the date from UTC to local time by subtracting the timezone offset in milliseconds
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Format the local date using the specified format
  let outputString = format(utcDate, dateFormat);

  // Append UTC offset if requested
  if (appendUtc) {
    outputString = `${outputString} (${getUtcOffset(date)})`;
  }

  // Return the formatted date string
  return outputString;
}

/**
 * Validates if the given timezone offset in seconds is within the valid range.
 *
 * @param {number} timezoneOffsetSeconds - The timezone offset in seconds.
 * @returns {boolean} - True if valid, otherwise false.
 */
export function isValidTimezoneOffset(timezoneOffsetSeconds: number) {
  // Check if the value is a number and within the acceptable range
  const MIN_OFFSET_SECONDS = -43200; // UTC-12:00 in seconds
  const MAX_OFFSET_SECONDS = 50400; // UTC+14:00 in seconds

  return (
    typeof timezoneOffsetSeconds === "number" &&
    timezoneOffsetSeconds >= MIN_OFFSET_SECONDS &&
    timezoneOffsetSeconds <= MAX_OFFSET_SECONDS
  );
}

/**
 * Compares a given date to the current date.
 *
 * @param {string | Date} dateString - The date to compare, provided as a string or Date object.
 * @returns {string} Returns "greater" if the date is in the future, "earlier" if in the past, or "equal" if it is today.
 */
export function compareDateToToday(dateString: string | Date): string {
  // Parse the input into a Date object
  const date1 = new Date(dateString);

  // Get the current date
  const date2 = new Date();

  // Compare the dates
  if (date1 > date2) {
    return "greater"; // date1 is in the future
  } else if (date1 < date2) {
    return "earlier"; // date1 is in the past
  } else {
    return "equal"; // date1 is the same as today
  }
}

/**
 * Validates a string to ensure it contains only letters, numbers, dots, or dashes,
 * and that the last character is a letter or number.
 *
 * @param {string} str - The string to validate.
 * @returns {object} An object with a boolean `valid` property and a `messages` array indicating validation errors.
 */
export function validateString(str: string): {
  valid: boolean;
  messages: string[];
} {
  // Extract the last character of the string
  const strLastChr = str.slice(-1);

  // Check if the string contains only allowed characters (letters, numbers, dots, or dashes)
  const strOnlyLettersAndNumbersPointLine = /^[A-Za-z0-9.-]*$/.test(str);

  // Check if the last character is a letter or number
  const strLastChrOnlyLettersAndNumbers = /^[A-Za-z0-9]*$/.test(strLastChr);

  // Initialize the return value with validation status and messages array
  let returnValue = {
    valid: strOnlyLettersAndNumbersPointLine && strLastChrOnlyLettersAndNumbers,
    messages: [] as string[],
  };

  // Add validation messages if checks fail
  if (!strOnlyLettersAndNumbersPointLine) {
    returnValue.messages.push(
      "Allowed characters are [a-z, A-Z, 0-9, . and -]."
    );
  }
  if (!strLastChrOnlyLettersAndNumbers) {
    returnValue.messages.push(
      "The last character must be one of [a-z, A-Z, 0-9]."
    );
  }

  // Return validation results
  return returnValue;
}

/**
 * Validates an environment variable name to ensure it contains only letters, digits, and underscores,
 * and does not start with a digit.
 *
 * @param {string} envValue - The environment variable name to validate.
 * @returns {object} An object with a boolean `valid` property and a `messages` array indicating validation errors.
 */
export function validateEnvVariable(envValue: string): {
  valid: boolean;
  messages: string[];
} {
  // Check if the name starts with a digit
  if (/^\d/.test(envValue)) {
    return {
      valid: false,
      messages: [
        "The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit.",
      ],
    };
  }

  // Check if the name contains only allowed characters (letters, digits, and underscores)
  if (/^[A-Za-z0-9_]+$/.test(envValue)) {
    return {
      valid: true,
      messages: [],
    };
  }

  // Return invalid result if the name contains invalid characters
  return {
    valid: false,
    messages: [
      "The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit.",
    ],
  };
}

/**
 * Generates a simple hash for a given string.
 * Note: This hash is not suitable for cryptographic purposes; it’s intended for obfuscation only.
 *
 * @param {string} input - The string to hash.
 * @returns {string} A hexadecimal string representing the hash.
 */
export function hashString(input: string): string {
  // Initialize hash with a specific seed value
  let hash = 5381;

  // Compute hash by iterating over each character in the input string
  for (let i = 0; i < input.length; i++) {
    // Shift hash left by 5 bits and add the current character code
    hash = (hash << 5) + hash + input.charCodeAt(i); // Equivalent to hash * 33 + c
  }

  // Return the hash as a hexadecimal string
  return hash.toString(16);
}

/**
 * Generates a cryptographically safe unique ID with an optional prefix.
 *
 * @param {string | undefined} prefix - An optional prefix to prepend to the generated ID.
 * @param {number} numberOfDigits - The number of characters in the generated ID (default: 12).
 * @returns {string} A unique ID string, optionally prefixed.
 */
export function generateId(
  prefix: string | undefined = undefined,
  numberOfDigits: number = 12
): string {
  // Custom alphabet for generating the ID
  const customAlphabet =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let id = "";

  // Generate a random character sequence of specified length
  for (let i = 0; i < numberOfDigits; i++) {
    id += customAlphabet.charAt(
      Math.floor(Math.random() * customAlphabet.length)
    );
  }

  // Return the ID with the optional prefix, if provided
  return prefix ? prefix + id : id;
}

/**
 * Validates a string to ensure it only contains URL-safe characters and meets a minimum length.
 *
 * @param {string} string - The string to validate.
 * @param {number} minLength - The minimum length required for the string (default: 6).
 * @param {boolean} allowedChar - Whether to enforce allowed URL-safe characters (default: true).
 * @returns {{ valid: boolean; messages: string[] }} An object containing a `valid` boolean and `messages` array.
 */
export function validateStringnonEncodingCharsInUrl(
  string: string,
  minLength: number = 6,
  allowedChar: boolean = true
): { valid: boolean; messages: string[] } {
  // Initialize the validation result with default values
  const validation = { valid: true, messages: [] as string[] };

  // Check if the string meets the minimum length requirement
  if (string.length < minLength) {
    validation.valid = false;
    validation.messages.push(`must be at least ${minLength} characters long`);
  }

  if (allowedChar) {
    // Regular expression to match characters that do not require URL encoding
    const nonEncodingCharsRegex = /^[a-zA-Z0-9-_.~]+$/;

    // Validate that the string contains only URL-safe characters
    if (!nonEncodingCharsRegex.test(string)) {
      validation.valid = false;
      validation.messages.push(
        "allowed characters (a-z, A-Z, 0-9, and - . _ ~)"
      );
    }
  }

  // Return the validation results
  return validation;
}

/**
 * Hides the middle part of a string, leaving only the start and end visible.
 *
 * @param {string} str - The string to obfuscate.
 * @param {number} visibleStart - The number of visible characters at the start (default: 2).
 * @param {number} visibleEnd - The number of visible characters at the end (default: 4).
 * @returns {string} The obfuscated string with the middle part hidden.
 */
export function hideMiddlePartOfString(
  str: string,
  visibleStart: number = 2,
  visibleEnd: number = 4
): string {
  // If the string is too short, return it as is
  if (str.length <= visibleStart + visibleEnd) {
    return str;
  }

  // Extract the start and end visible parts of the string
  const firstVisiblePart = str.slice(0, visibleStart);
  const lastVisiblePart = str.slice(str.length - visibleEnd);

  // Create the hidden part with a fixed number of asterisks
  const hiddenPart = "*".repeat(4);

  // Concatenate the visible parts with the hidden part in between
  return firstVisiblePart + hiddenPart + lastVisiblePart;
}

/**
 * Converts a value in bytes to gigabytes, rounded to a specified number of decimal places.
 *
 * @param {number} bytes - The value in bytes to convert.
 * @param {number} decimals - The number of decimal places to round to (default: 2).
 * @returns {number} The converted value in gigabytes.
 */
export function bytesToGigabytes(bytes: number, decimals: number = 2): number {
  // If bytes is 0 or undefined, return 0
  if (!bytes) return 0;

  // Convert bytes to gigabytes and round to the specified number of decimal places
  return parseFloat((bytes / 1073741824).toFixed(decimals));
}

/**
 * Converts a value in gigabytes to bytes.
 *
 * @param {number} gigabytes - The value in gigabytes to convert.
 * @returns {number} The converted value in bytes.
 */
export function gigabytesToBytes(gigabytes: number): number {
  // If gigabytes is 0 or undefined, return 0
  if (!gigabytes) return 0;

  // Convert gigabytes to bytes
  const bytes = gigabytes * 1073741824;
  return bytes;
}

/**
 * Converts a value in bytes to a human-readable format, using the appropriate size unit.
 *
 * @param {number} bytes - The value in bytes to format.
 * @param {number} decimals - The number of decimal places to include (default: 2).
 * @returns {string} The formatted string with the size in appropriate units.
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  // If bytes is 0 or undefined, return "0 Bytes"
  if (!bytes) return "0 Bytes";

  // Define the threshold for each unit (1000 bytes per unit)
  const k = 1000;

  // Ensure decimal places are non-negative
  const dm = decimals < 0 ? 0 : decimals;

  // Define size units for human-readable formatting
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // Determine the appropriate size unit based on the logarithmic scale
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Calculate the converted size and format it to the specified decimal places
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * Truncates a string to a specified length and adds ellipsis if it exceeds that length.
 *
 * @param {string} input - The string to truncate.
 * @param {number} cutLength - The maximum length of the truncated string (default: 20).
 * @returns {string} The truncated string with ellipsis if it exceeds the specified length.
 */
export function truncateString(input: string, cutLength: number = 20): string {
  // Check if the input length exceeds the cut length; if so, truncate and add "..."
  return input.length > cutLength
    ? `${input.substring(0, cutLength)}...`
    : input;
}

/**
 * Checks if a given string is a valid slug.
 *
 * A valid slug contains only lowercase letters, numbers, and hyphens,
 * and does not start or end with a hyphen or contain consecutive hyphens.
 *
 * @param {string} slug - The string to validate as a slug.
 * @returns {boolean} Returns `true` if the string is a valid slug, otherwise `false`.
 */
export function isValidSlug(slug: string): boolean {
  // Define a regular expression pattern for a valid slug
  const slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  // Check if the slug matches the pattern
  return slugPattern.test(slug);
}

/**
 * Generates a random ID by combining a random component and a timestamp, both converted to base 36.
 *
 * @returns {string} A unique random ID string.
 */
export function generateRandomId(): string {
  // Generate a random number, convert it to base 36, and take a substring for the random part
  const randomPart = Math.random().toString(36).substring(2, 11); // 9-character random string

  // Append the current timestamp (converted to base 36) to ensure uniqueness even if generated in rapid succession
  const timestampPart = Date.now().toString(36);

  // Combine the random and timestamp parts to form the ID
  const randomId = randomPart + timestampPart;

  // Return the unique ID
  return randomId;
}

/**
 * Creates a deep copy of an object, array, or Map, recursively cloning nested structures.
 *
 * @param {any} obj - The object, array, or Map to deep copy.
 * @returns {any} A deep copy of the input.
 */
export function deepCopy<T>(obj: T): T {
  // If obj is not an object or is null, return it as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // If obj is a Map, use deepCopyMap to create a deep copy
  if (obj instanceof Map) {
    return deepCopyMap(obj) as T;
  }

  // If obj is an array or plain object, create a new instance and copy properties recursively
  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCopy((obj as any)[key]);
    }
  }

  return newObj;
}

/**
 * Creates a deep copy of a Map, recursively cloning any nested objects or Maps within keys and values.
 *
 * @param {Map<any, any>} originalMap - The original Map to deep copy.
 * @returns {Map<any, any>} A new Map with deeply copied keys and values.
 */
export function deepCopyMap<K, V>(originalMap: Map<K, V>): Map<K, V> {
  const newMap = new Map<K, V>();

  originalMap.forEach((value, key) => {
    // Recursively clone the value if it is an object or another Map
    const clonedValue =
      typeof value === "object" && value !== null ? deepCopy(value) : value;

    // Recursively clone the key if it is an object or another Map
    const clonedKey =
      typeof key === "object" && key !== null ? deepCopy(key) : key;

    // Set the cloned key-value pair in the new Map
    newMap.set(clonedKey as K, clonedValue as V);
  });

  return newMap;
}

/**
 * Converts a Map to a plain object, recursively converting nested Maps to objects.
 *
 * @param {Map<any, any>} map - The Map to convert.
 * @returns {Record<string, any>} An object representation of the Map.
 */
export function mapToObject(map: Map<any, any>): Record<string, any> {
  const obj: Record<string, any> = {};

  for (const [key, value] of map) {
    // Recursively convert nested Maps to objects
    obj[String(key)] = value instanceof Map ? mapToObject(value) : value;
  }

  return obj;
}

/**
 * Converts an object to a Map, recursively converting nested plain objects to Maps.
 *
 * @param {Record<string, any>} obj - The object to convert.
 * @returns {Map<string, any>} A Map representation of the object.
 */
export function objectToMap(obj: Record<string, any>): Map<string, any> {
  const map = new Map<string, any>();

  // Iterate over the object's entries and convert each to a Map entry
  for (const [key, value] of Object.entries(obj)) {
    // Recursively convert plain objects to Maps
    if (value && typeof value === "object" && !Array.isArray(value)) {
      map.set(key, objectToMap(value as Record<string, any>));
    } else {
      // Otherwise, directly set the value in the Map
      map.set(key, value);
    }
  }

  return map;
}

/**
 * Safely parses a JSON string with configurable error handling.
 *
 * @param str - The JSON string to parse.
 * @param onError - Action to take on parsing failure:
 *                  - "returnOriginal" (default): Returns the original string if parsing fails.
 *                  - "throw": Throws an error if parsing fails.
 * @returns The parsed JSON object, the original string (if parsing fails and onError is "returnOriginal"),
 *          or null if the input string is falsy.
 */
export function safelyParseJSON(
  str: string,
  onError: "returnOriginal" | "throw" = "returnOriginal"
) {
  if (!str) {
    return null; // Return null if the input string is falsy
  }

  if (typeof str === "string") {
    try {
      // Attempt to parse the string as JSON
      return JSON.parse(str);
    } catch (error: any) {
      console.error(`Error parsing to JSON str ${str}`, error);
      // Handle parsing failure based on onError setting
      if (onError === "throw") {
        //TODO:TIZ:Based on running env, client or server, used the correct method to thrwo the error
        throwServerError(
          "JsonParsingError",
          `Failed to parse JSON str ${str}: ${error.message}`,
          400
        );
      }
      // Return the original string if parsing fails and onError is "returnOriginal"
      return str;
    }
  }

  return null; // Return null if the input is not a string
}

/**
 * Generates a unique UUID.
 *
 * The operation ID is prefixed with "op_" followed by a cryptographically secure UUID.
 * This ensures the ID is unique and identifiable as an operation.
 *
 * @returns {string} A unique operation ID.
 *
 * @example
 * const operationId = generateUUID("op");
 * console.log(operationId); // "op_abcd1234-5678-90ef-ghij-klmnopqrstuv"
 */
export function generateUUID(prefix: string): string {
  return `${prefix}_${crypto.randomUUID()}`;
}
