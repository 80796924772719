import { useGetCurrentProjectId } from "@custom-hooks/projects";
import { useFetchData } from "@custom-hooks/useFetchData";
import { Backups } from "@data-types/databases-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { useEffect, useState } from "react";

/**
 * Custom hook to fetch and manage the backups for a specific database within a project.
 * Polling is enabled if there are no backups.
 *
 * @param {string} databaseName - The name of the database for which backups are fetched.
 *
 * @returns {FetchHookResult<Backups>} The result object with the list of backups, UI state indicators,
 *   and a function to manage fetched data.
 */
export function useGetDatabaseBackup(
  databaseName: string
): FetchHookResult<Backups> {
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const projectId = useGetCurrentProjectId();

  const { data, ...fetchDetails } = useFetchData<Backups>(
    projectId !== "",
    `/api/projects/${projectId}/backup/${databaseName}`,
    "useGetDatabaseBackup",
    "backend",
    { refreshInterval: isPolling ? 10000 : 0, revalidateOnFocus: false }
  );

  useEffect(() => {
    setIsPolling(data?.length === 0);
  }, [data]);

  return {
    data,
    ...fetchDetails,
  };
}
