import { AddonLookupKey, AddonsFunctionality } from "@data-types/billing-types";
import { addons } from "@lib/billing/constants";

/**
 * Custom hook to determine if a specific functionality is enabled for a given add-on
 * and retrieve its associated value or limit.
 *
 * @param {AddonLookupKey} addon - The add-on to check for functionality details.
 * @param {AddonsFunctionality} functionality - The specific functionality to check.
 *
 * @returns {Object} An object containing the following properties:
 * - `isEnabled` (boolean): Indicates whether the specified functionality is enabled
 *   for the given add-on.
 * - `limit` (number | undefined): The value or limit associated with the functionality
 *   if enabled; otherwise, `undefined`.
 */
export const useGetFunctionalityDetails = (
  addon: AddonLookupKey | null,
  functionality: AddonsFunctionality
) => {
  if (addon === null) {
    return {
      isEnabled: undefined,
      limit: undefined,
    };
  }
  const isEnabled = addons[addon]?.includedFunctionality
    ? Object.keys(addons[addon].includedFunctionality).includes(functionality)
    : false;

  return {
    isEnabled: isEnabled,
    limit: isEnabled
      ? addons[addon]?.includedFunctionality[functionality]
      : undefined,
  };
};
