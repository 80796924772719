//
// ProjectCard.tsx
//

import EditProject from "@components/Projects/EditProject";
import {
  useGetCurrentOrganizationId,
  useGetSubscriptionDetails,
} from "@custom-hooks/billing";
import { useGetProjectLookupKey } from "@custom-hooks/billing/hooks/useGetProjectLookupKey";
import { useGetProjectJobs } from "@custom-hooks/jobs";
import { useGetProjectNodes } from "@custom-hooks/nodes";
import {
  convertEnvCodeToEnvString,
  useGetProjectStorageInfo,
} from "@custom-hooks/projects";
import { AddonLookupKey } from "@data-types/billing-types";
import { Project } from "@data-types/projects-types";
import OpenDialog from "@generic-components/OpenDialog";
import * as Headless from "@headlessui/react";
import DotsHorizontalIcon from "@layouts/svg-icon/dots-horizontal-icon.svg";
import { SQLITE_CLOUD_ORGANIZATION_ID } from "@lib/billing/constants";
import { SubscriptionStatusDialog } from "@tw-components/billing/SubscriptionStatusDialog";
import { UpgradeDialog } from "@tw-components/billing/upgrade-dialog/UpgradeDialog";
import { ConnectDialog } from "@tw-components/projects/connect-dialog/ConnectDialog";
import { DeleteProjectDialog } from "@tw-components/projects/DeleteProjectDialog";
import { Alerts, MessageType } from "@tw-components/ui/alerts";
import { Button } from "@tw-components/ui/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@tw-components/ui/dropdown";
import { Link } from "@tw-components/ui/link";
import { NavbarItem } from "@tw-components/ui/navbar";
import { ProgressBar } from "@tw-components/ui/progress-bar";
import { TooltipLabel } from "@tw-components/ui/tooltip";
import clsx from "clsx";
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import { ContactDialog } from "./ContactDialog";

type ProjectCardProps = {
  project?: Project | undefined;
  width?: string;
};

/**
 * ProjectCard Component
 *
 * This component displays a card for a project, showing details like name, description,
 * region, storage, and interactive options for connecting, editing, or deleting the project.
 *
 * A project is considered functional when at least one node is responding,
 * which can be determined by retrieving storage information.
 *
 * - While there are active jobs:
 *   - The project is not navigable.
 *   - The project cannot be deleted.
 *   - The connect modal cannot be opened.
 *   - Jobs with errors are not displayed.
 *
 * - When there are no active jobs:
 *   - Check if there are any jobs with errors.
 *   - If there are errors, display the button to open the modal for viewing the errors.
 * @param {ProjectCardProps} props - Component properties
 * @param {Project | undefined} props.project - The project data, if available
 * @param {string} [props.width="tw-w-[36rem]"] - The width of the card
 *
 * @returns {JSX.Element} The rendered ProjectCard component
 */
export function ProjectCard({
  project = undefined,
  width = "tw-w-[36rem]",
}: ProjectCardProps) {
  const {
    id = "",
    name = "SQLite Cloud Project",
    description = "A description of the project.",
    nodes_count = 0,
    env = 0,
    regionsList,
  } = project || {};

  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showJobsErrorDialog, setShowJobsErrorDialog] = useState(false);
  const [jobsErrorDialogProps, setJobsErrorDialogProps] = useState<{
    description?: string;
    errors: string[];
  }>({ description: undefined, errors: [] });
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] =
    useState(false);
  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] =
    useState(false);
  const editProjectDialogRef = useRef<MutableRefObject<any | null>>(null);

  const { mutate: updateProjectNodes } = useGetProjectNodes(id);

  const {
    data: storageInfo,
    isLoading: loadingStorageInfo,
    hasData: availableStorageInfo,
    noDatabases: noDatabases,
    mutate: updateProjectStorageInfo,
  } = useGetProjectStorageInfo(id);

  const {
    isAnyJobInProgress,
    isAnyJobWithError,
    projectStatusString,
    projectErrorString,
    errors: jobsErrors,
    isLoading: isLoadingJobs,
  } = useGetProjectJobs(project?.id ?? "");

  const { subscriptionStatus, subscriptionStatusAlertProps } =
    useGetSubscriptionDetails();

  const organizationId = useGetCurrentOrganizationId();

  const projectType = useGetProjectLookupKey(project?.id ?? "");

  const envStatus = convertEnvCodeToEnvString(env);

  const availableStorage = availableStorageInfo
    ? storageInfo?.availableStorage
    : "";
  const usedStorage = availableStorageInfo ? storageInfo?.usedStorage : "";
  const storagePercentage = availableStorageInfo
    ? storageInfo?.usedStoragePercentage
    : 0;

  const warning = availableStorageInfo ? storageInfo?.warning : false;

  const isProjectWorking = availableStorageInfo && nodes_count > 0;

  const isClickable =
    organizationId === SQLITE_CLOUD_ORGANIZATION_ID ||
    (!isAnyJobInProgress && isProjectWorking);

  const isProjectDisabled =
    subscriptionStatus === "unpaid" &&
    projectType !== AddonLookupKey.SANDBOX_PROJECT;

  const dropdownItems = [
    {
      children: "Connect",
      disabled: !isClickable,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();

        if (isProjectDisabled) {
          setShowSubscriptionStatusDialog(true);
        } else {
          setIsConnectDialogOpen(true);
        }
      },
    },
    {
      children: "Edit details",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isProjectDisabled) {
          setShowSubscriptionStatusDialog(true);
        } else {
          // @ts-expect-error OpenDialog not writter for typescript
          editProjectDialogRef.current?.open();
        }
      },
    },
    {
      children: "Delete project",
      disabled: isAnyJobInProgress,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsDeleteProjectDialogOpen(true);
      },
    },
  ];

  useEffect(() => {
    if (!isAnyJobInProgress) {
      updateProjectStorageInfo?.();
      updateProjectNodes?.();
    }
  }, [isAnyJobInProgress]);

  return (
    <>
      <Link
        href={
          id && isClickable && !isProjectDisabled
            ? `/projects/${id}/databases`
            : "#"
        }
        className={clsx(
          width,
          isClickable ? "tw-cursor-pointer" : "!tw-cursor-default",
          "tw-rounded-2xl tw-border  focus:tw-outline-none data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-2",
          "tw-bg-interface-card-light dark:tw-bg-interface-card-dark",
          "tw-dark:tw-border-interface-divider-dark tw-border-interface-divider-light",
          "tw-dark:hover:tw-border-interface-gray-light hover:tw-border-interface-gray-light",
          "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark data-[focus]:tw-outline-brandBlues-brandDark-light",
          "tw-transition-border tw-duration-200 tw-ease-in-out"
        )}
        onClick={
          isProjectDisabled
            ? () => setShowSubscriptionStatusDialog(true)
            : undefined
        }
      >
        <div className="tw-relative tw-h-full tw-w-full">
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-y-5 tw-overflow-hidden tw-px-5 tw-pb-5 tw-pt-3">
            <div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-dark:tw-text-text-title-dark tw-text-16px-semiBold tw-text-text-title-light">
                  {name}
                </div>

                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2">
                    <Headless.Button as={Fragment}>
                      {({ hover }) => (
                        <span
                          className={clsx(
                            isProjectWorking
                              ? "tw-dark:tw-bg-semantics-success-dark tw-bg-semantics-success-light"
                              : "tw-dark:tw-bg-semantics-error-dark tw-bg-semantics-error-light",
                            "tw-relative tw-h-3 tw-w-3 tw-rounded-full tw-ring-2 tw-ring-white"
                          )}
                        >
                          {hover && (
                            <TooltipLabel className="tw-absolute -tw-bottom-[6px] tw-right-[0]">
                              {isProjectWorking ? "Online" : "Offline"}
                            </TooltipLabel>
                          )}
                        </span>
                      )}
                    </Headless.Button>

                    <div
                      className={clsx(
                        "tw-h-fit tw-rounded-2xl tw-px-3 tw-py-1",
                        "tw-dark:tw-text-text-title-dark tw-font-mono tw-text-12px-medium tw-lowercase tw-text-text-title-light",
                        env === 0 && "tw-dark:tw-bg-[#FEE0B4] tw-bg-[#FEE0B4]",
                        env === 1 &&
                          "tw-dark:tw-bg-brandBlues-ice-dark tw-bg-brandBlues-ice-light",
                        env === 2 && "tw-dark:tw-bg-[#C3E9BA] tw-bg-[#C3E9BA]"
                      )}
                    >
                      {envStatus.label}
                    </div>
                  </div>

                  <Dropdown>
                    <DropdownButton as={NavbarItem}>
                      <div className="tw-text-brandBlues-brandDark-light">
                        <DotsHorizontalIcon />
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-64" anchor="bottom end">
                      {dropdownItems.map((item, i) => (
                        <DropdownItem
                          key={i}
                          onClick={item.onClick}
                          disabled={item.disabled}
                        >
                          <DropdownLabel>{item.children}</DropdownLabel>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>

              <div
                className={clsx(
                  "tw-dark:tw-text-text-subTitle-dark tw-mt-1 tw-text-14px-regular tw-text-text-subTitle-light sm:-tw-mt-1"
                )}
              >
                <>
                  {Array.isArray(regionsList) && regionsList?.length > 0 ? (
                    <div className="tw-flex tw-flex-row tw-gap-x-2">
                      {regionsList.map((region) => (
                        <div key={region && region.code}>
                          {region && region.code}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      No region for this project
                    </span>
                  )}
                </>
              </div>
            </div>

            <div className="tw-dark:tw-text-text-subTitle-dark tw-line-clamp-2 tw-max-w-[24.75rem] tw-text-14px-light tw-text-text-subTitle-light">
              {description}
            </div>

            <div className="tw-flex-1" />

            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-gap-4">
              <div className="tw-flex tw-max-w-[21rem] tw-flex-1 tw-flex-col tw-gap-y-2">
                {(isAnyJobInProgress || isProjectWorking) && (
                  <ProgressBar
                    value={storagePercentage as number}
                    variant={warning ? "error" : "base"}
                    type={isAnyJobInProgress ? "indeterminate" : "determinate"}
                  />
                )}

                <div className="tw-dark:tw-text-text-body-dark tw-text-14px-regular tw-text-text-body-light">
                  {isAnyJobInProgress ? (
                    projectStatusString
                  ) : loadingStorageInfo ? (
                    <div className="tw-h-4 tw-w-20 tw-animate-pulse tw-rounded tw-bg-interface-gray-light dark:tw-bg-interface-gray-dark"></div>
                  ) : !isProjectWorking ? (
                    <span className=" tw-dark:tw-text-semantics-error-dark tw-text-16px-medium tw-text-semantics-error-light"></span>
                  ) : isProjectWorking ? (
                    `${usedStorage} / ${availableStorage}`
                  ) : noDatabases ? (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      {nodes_count ? "No databases for this project" : ""}
                    </span>
                  ) : (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      Error loading storage info
                    </span>
                  )}
                </div>
              </div>

              {!isLoadingJobs &&
                !isAnyJobInProgress &&
                !isAnyJobWithError &&
                isProjectWorking &&
                projectType === AddonLookupKey.SANDBOX_PROJECT && (
                  <Button
                    variant="primary"
                    size="small"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowUpgradeDialog(true);
                    }}
                    label="Upgrade to Pro"
                    className="tw-w-32"
                  />
                )}

              {!isLoadingJobs && !isAnyJobInProgress && isAnyJobWithError && (
                <Button
                  variant="destructive"
                  size="small"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowJobsErrorDialog(true);
                    setJobsErrorDialogProps({
                      description: projectErrorString,
                      errors: jobsErrors,
                    });
                  }}
                  label="Check Errors"
                  className="tw-w-32"
                />
              )}
            </div>
          </div>
        </div>
      </Link>

      <ConnectDialog
        isOpen={isConnectDialogOpen}
        onClose={() => {
          setIsConnectDialogOpen(false);
        }}
        projectId={id}
      />

      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={editProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        {/* @ts-expect-error OpenDialog not writter for typescript */}
        <EditProject project={project} />
      </OpenDialog>

      <DeleteProjectDialog
        project={{ name, id }}
        isOpen={isDeleteProjectDialogOpen}
        onClose={() => setIsDeleteProjectDialogOpen(false)}
      />

      {subscriptionStatusAlertProps && (
        <SubscriptionStatusDialog
          {...subscriptionStatusAlertProps}
          isOpen={showSubscriptionStatusDialog}
          onClose={() => setShowSubscriptionStatusDialog(false)}
        />
      )}

      <UpgradeDialog
        isOpen={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
        projectId={project?.id || ""}
      />

      <ContactDialog
        title="Project Errors"
        description={jobsErrorDialogProps.description}
        body={
          <>
            <Alerts
              messages={jobsErrorDialogProps.errors.map((error) => {
                return { title: error, type: MessageType.Error };
              })}
            />
          </>
        }
        isOpen={showJobsErrorDialog}
        onClose={() => setShowJobsErrorDialog(false)}
        textAreaLabel={"Your Message"}
        submitButtonText={"Send Error Report"}
        error={jobsErrorDialogProps.errors}
        canSendEmptyMessage={true}
        type={"contact_us"}
        source={"Project Card"}
      />
    </>
  );
}
