//
// ProjectCard.tsx
//

import { useGetCurrentOrganizationId } from "@custom-hooks/billing";
import { useGroupProjectsByLookupKey } from "@custom-hooks/billing/hooks/useGroupProjectsByLookupKey";
import { AddonLookupKey } from "@data-types/billing-types";
import AddCircleIcon from "@layouts/svg-icon/add-circle-icon.svg";
import CreateProjectBackground from "@layouts/svg-illustration/create-project.svg";
import { SQLITE_CLOUD_ORGANIZATION_ID } from "@lib/billing/constants";
import { CreateProjectDialog } from "@tw-components/projects/CreateProjectDialog";
import { Button } from "@tw-components/ui/button";
import clsx from "clsx";
import { useState } from "react";

/**
 * CreateProjectCard Component
 *
 * This component renders a styled button card for adding a new project.
 * It uses Headless UI's Button component with additional styling and properties
 * passed from CreateProjectCardProps.
 *
 * @param {string} [props.width="tw-w-[36rem]"] - Optional width for the button card, defaults to "tw-w-[36rem]".
 *
 * @returns {JSX.Element} The rendered CreateProjectCard component.
 */
export type CreateProjectCardProps = {
  width?: string;
};

export function CreateProjectCard({
  width = "tw-w-[36rem]",
}: CreateProjectCardProps) {
  const organizationId = useGetCurrentOrganizationId();

  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false);
  const [selectedProjectType, setSelectedProjectType] =
    useState<AddonLookupKey>(AddonLookupKey.SANDBOX_PROJECT);

  const groupedProjects = useGroupProjectsByLookupKey();
  const sandboxProjectCount =
    groupedProjects?.[AddonLookupKey.SANDBOX_PROJECT]?.quantity;

  const handleCreateProject = (projectType: AddonLookupKey) => {
    setSelectedProjectType(projectType);
    setShowCreateProjectDialog(true);
  };

  return (
    <>
      <div
        className={clsx(
          // Base
          "tw-relative tw-overflow-hidden",
          // Size
          width,
          "tw-h-52",
          // Base
          "tw-rounded-2xl tw-border tw-border-transparent focus:tw-outline-none data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-2",
          // Background
          "tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark"
        )}
      >
        <CreateProjectBackground
          width="100%"
          className="tw-pointer-events-none tw-absolute tw-bottom-0"
        />

        <div className={"tw-relative tw-h-full tw-w-full"}>
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-y-4 tw-overflow-hidden">
            {(organizationId === SQLITE_CLOUD_ORGANIZATION_ID ||
              sandboxProjectCount === 0) && (
              <Button
                variant="secondary"
                label={"Create Sandbox Project"}
                size="large"
                icon={<AddCircleIcon />}
                onClick={() =>
                  handleCreateProject(AddonLookupKey.SANDBOX_PROJECT)
                }
                className="tw-w-52"
              />
            )}

            <Button
              variant="primary"
              label={"Create Pro Project"}
              size="large"
              icon={<AddCircleIcon />}
              onClick={() => handleCreateProject(AddonLookupKey.PRO_PROJECT)}
              className="tw-w-52"
            />
          </div>
        </div>
      </div>

      <CreateProjectDialog
        isOpen={showCreateProjectDialog}
        onClose={() => setShowCreateProjectDialog(false)}
        projectType={selectedProjectType}
      />
    </>
  );
}
