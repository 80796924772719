//
// DeleteProjectDialog.tsx
//

import {
  useCancelSubscription,
  useGetSubscriptionDetails,
  useUpdateSubscription,
} from "@custom-hooks/billing";
import { useGetProjectLookupKey } from "@custom-hooks/billing/hooks/useGetProjectLookupKey";
import { useGroupProjectsByLookupKey } from "@custom-hooks/billing/hooks/useGroupProjectsByLookupKey";
import { useDeleteFreeProject } from "@custom-hooks/projects";
import { AddonActionNames, AddonLookupKey } from "@data-types/billing-types";
import { Project } from "@data-types/projects-types";
import {
  FAILED_TO_CANCEL_SUBSCRIPTION_ERROR_MESSAGE,
  FAILED_TO_LOAD_SUBSCRIPTION_DETAILS_ERROR_MESSAGE,
} from "@lib/billing/constants";
import { SubscriptionStatusDialog } from "@tw-components/billing/SubscriptionStatusDialog";
import { MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericInput } from "@tw-components/ui/input";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

/**
 * Props for the DeleteProjectDialog component.
 *
 * @property {Pick<Project, "id" | "name">} project - The project to be deleted.
 * @property {boolean} isOpen - Whether the dialog is open.
 * @property {() => void} onClose - Function to call when the dialog is closed.
 */
type DeleteProjectDialogProps = {
  project: Pick<Project, "id" | "name">;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A dialog component for deleting a project.
 *
 * @param {DeleteProjectDialogProps} props - The props for the component.
 *
 * @returns {JSX.Element} The JSX element for the DeleteProjectDialog component.
 */
export function DeleteProjectDialog({
  project,
  isOpen,
  onClose,
}: DeleteProjectDialogProps): JSX.Element {
  const confirmString = "delete my project";

  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] =
    useState(false);

  const { subscriptionStatusAlertProps } =
    useGetSubscriptionDetails();

  const { deleteProject } = useDeleteFreeProject({});
  const { updateSubscription } = useUpdateSubscription({});
  const { cancelSubscription } = useCancelSubscription({});

  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .required("Project name is required.")
      .oneOf(
        [project.name],
        "Project name must match to the existing project name."
      ),
    deleteConfirmation: Yup.string()
      .required("Confirmation text is required.")
      .oneOf([confirmString], `You must type "${confirmString}" to confirm.`),
  });

  const projectType = useGetProjectLookupKey(project.id);
  const groupedProjects = useGroupProjectsByLookupKey();

  const isDeletingLastPaidProject =
    groupedProjects?.paid_projects.quantity === 1 &&
    (projectType === AddonLookupKey.PRO_PROJECT ||
      projectType === AddonLookupKey.SCALE_PROJECT);

  return (
    <>
      <Formik
        initialValues={{ projectName: "", deleteConfirmation: "" }}
        validationSchema={validationSchema}
        onSubmit={async (_, { setSubmitting, setStatus }) => {
          setStatus(undefined);
          setSubmitting(true);

          if (UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true") {
            setStatus({
              type: MessageType.Error,
              title: "Infrastructure Upgrade in Progress",
              description:
                "We're upgrading our infrastructure to serve you better! Creating a new project is temporarily disabled. Please try again later.",
            });

            return;
          }

          switch (projectType) {
            case AddonLookupKey.SCALE_PROJECT:
            case AddonLookupKey.PRO_PROJECT:
              if (isDeletingLastPaidProject) {
                const cancelSubscriptionResult = await cancelSubscription([
                  project.id,
                ]);

                if (cancelSubscriptionResult.success) {
                  onClose();
                } else {
                  setStatus(FAILED_TO_CANCEL_SUBSCRIPTION_ERROR_MESSAGE);
                }
              } else {
                const updateSubscriptionResult = await updateSubscription({
                  newAddon: {
                    addon: AddonLookupKey.PRO_PROJECT,
                    quantity: -1,
                    action: {
                      name: AddonActionNames.DELETE,
                      data: {
                        projectId: project.id,
                      },
                    },
                  },
                });

                if (updateSubscriptionResult.success) {
                  onClose();
                } else {
                  setStatus({
                    type: MessageType.Error,
                    title: "Failed to Delete Project",
                    description:
                      "An error occurred while trying to delete your project and update your subscription. Please try again later. If the issue persists, contact us for assistance.",
                  });
                }
              }

              break;
            case AddonLookupKey.SANDBOX_PROJECT:
              const deleteProjectResult = await deleteProject(project.id);

              if (deleteProjectResult.success) {
                onClose();
              } else {
                setStatus({
                  type: MessageType.Error,
                  title: "Failed to Delete Project",
                  description:
                    "An error occurred while trying to delete your project. Please try again later. If the issue persists, contact us for assistance.",
                });
              }

              break;
            default:
              setStatus(FAILED_TO_LOAD_SUBSCRIPTION_DETAILS_ERROR_MESSAGE);
              break;
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          status,
          setStatus,
          handleSubmit,
          errors,
          resetForm,
        }) => (
          <GenericDialog
            onClose={onClose}
            beforeEnter={() => {
              if (subscriptionStatusAlertProps) {
                switch (projectType) {
                  case AddonLookupKey.PRO_PROJECT:
                  case AddonLookupKey.SCALE_PROJECT:
                    onClose();
                    setShowSubscriptionStatusDialog(true);
                    break;
                  case AddonLookupKey.SANDBOX_PROJECT:
                    break;
                }
              }
            }}
            afterLeave={resetForm}
            open={isOpen}
            title="Delete Project"
            description={`Are you sure you want to delete ${project.name}?`}
            body={
              <Form className="tw-flex tw-flex-col tw-gap-4">
                <GenericInput
                  sizeVar="medium"
                  label={`To verify, type “${project.name}” below to remove this project from your organization`}
                  value={values.projectName}
                  name="projectName"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setStatus(undefined);
                  }}
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  errors={errors}
                />

                <GenericInput
                  sizeVar="medium"
                  label={`To verify, type “${confirmString}” below`}
                  name="deleteConfirmation"
                  value={values.deleteConfirmation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setStatus(undefined);
                  }}
                  disabled={isSubmitting}
                  onBlur={handleBlur}
                  errors={errors}
                />
              </Form>
            }
            alertMessage={status}
            submitButtonProps={{
              size: "medium",
              variant: "destructive",
              label: "Delete",
              onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                handleSubmit();
              },
              className: "tw-w-[6.625rem]",
              disabled: !isValid,
              showLoader: isSubmitting,
            }}
            secondaryButtonProps={{ text: "Cancel", onClick: onClose }}
            size="3xl"
            buttonLayoutType="row"
          />
        )}
      </Formik>

      {subscriptionStatusAlertProps && (
        <SubscriptionStatusDialog
          {...subscriptionStatusAlertProps}
          isOpen={showSubscriptionStatusDialog}
          onClose={() => setShowSubscriptionStatusDialog(false)}
        />
      )}
    </>
  );
}
