import { ClientError } from "@lib/client-side";
import { Alerts, MessageType } from "@tw-components/ui/alerts";
import { useState } from "react";
import { GenericErrorDialog } from "./GenericErrorDialog";

/**
 * Props for the GlobalErrorDialog component.
 * 
 * @property {string} title - The title of the error dialog.
 * @property {string} message - The message displayed in the error dialog.
 * @property {ClientError} [error] - A client-side error object provided to the ContactDialog, 
 * to include error details when sending the user message.
 */
export type GlobalErrorDialogProps = {
  title: string;
  message: string;
  error?: ClientError;
};

/** 
 * This component is rendered programmatically when an error occurs, inside 
 * a globally managed error handling system. The component is mounted into a 
 * DOM element (an "error-portal" container) created dynamically if it does not 
 * already exist.
 * 
 * @param {GlobalErrorDialogProps} props - The properties for the component.
 * 
 * @returns {JSX.Element} The rendered GlobalErrorDialog component.
 */
export function GlobalErrorDialog({
  title,
  message,
  error,
}: GlobalErrorDialogProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <GenericErrorDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={title}
      body={<Alerts messages={[{ type: MessageType.Error, title: message }]} />}
      error={error}
    />
  );
}
