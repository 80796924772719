import { Region } from "@data-types/regions-types";

const regions: Region[] = [
  {
    code: "us-east-2",
    description: "US East (Ohio)",
    region_id: "aws-us-east-2",
  },
  {
    code: "us-west-1",
    description: "US West (N. California)",
    region_id: "aws-us-west-1",
  },
  {
    code: "ap-southeast-1",
    description: "Asia Pacific (Singapore)",
    region_id: "aws-ap-southeast-1",
  },
  {
    code: "eu-central-1",
    description: "Europe (Frankfurt)",
    region_id: "aws-eu-central-1",
  },
  {
    code: "eu1",
    description: "Test K8s (Frankfurt)",
    region_id: "k8s-eu1",
  },
  {
    code: "us1",
    description: "Test K8s (N. California)",
    region_id: "k8s-us1",
  },
];

/**
 * Finds a region by its region ID.
 *
 * @param regionId - The region ID to search for.
 * @returns The matching Region object, or undefined if not found.
 */
export function getRegionById(
  regionId: Region["region_id"]
): Region | undefined {
  return regions.find((region) => region.region_id === regionId);
}
