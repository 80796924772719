import { AddonLookupKey } from "@data-types/billing-types";
import { addons } from "@lib/billing/constants";
import clsx from "clsx";

/**
 * Props for the AddonPriceDetails component.
 *
 * @typedef {Object} AddonPriceDetailsProps
 * @property {string} [className] - Additional class names for styling the component.
 * @property {AddonLookupKey} addonKey - The key identifying the add-on.
 * @property {boolean} [includeDiscountReason] - Whether to include the discount reason in the display.
 */
type AddonPriceDetailsProps = {
  className?: string;
  addonKey: AddonLookupKey;
  includeDiscountReason?: boolean;
};

/**
 * Generates a JSX element displaying the pricing information for a given add-on.
 *
 * - If there is a discount, the base price is crossed out and the discounted price is shown.
 * - If there is no discount, only the base price is shown.
 * - If the base price is 0, "Free" is displayed.
 *
 * @param {AddonPriceDetailsProps} props - The props for the component.
 *
 * @returns {JSX.Element} A JSX element displaying the pricing information.
 */
export function AddonPriceDetails({
  className = "",
  addonKey,
  includeDiscountReason = false,
}: AddonPriceDetailsProps): JSX.Element {
  const pricing = addons[addonKey].monthlyPricing;
  const pricingSuffix = "/mo";

  if (pricing.discountDetails) {
    return (
      <div className={clsx(className, "tw-flex tw-flex-col tw-gap-2")}>
        <div className="tw-flex tw-flex-row tw-gap-2 sm:tw-flex-wrap">
          <span className="tw-w-full tw-line-through tw-opacity-60 sm:tw-w-auto">
            ${pricing.basePrice}
            {pricingSuffix}
          </span>
          <span className="tw-w-full tw-font-semibold sm:tw-w-auto">
            ${pricing.discountDetails.discountedPrice}
            {pricingSuffix}
          </span>
        </div>
        {includeDiscountReason && (
          <span>[{pricing.discountDetails.discountReason}]</span>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      {pricing.basePrice === 0
        ? "Free"
        : `${pricing.basePrice}${pricingSuffix}`}
    </div>
  );
}
