//
// getJobStatus.ts
//

import { Job, JobProgressStatus } from "@data-types/jobs-type";

/**
 * Determines the status of a given job based on its error state and progress.
 *
 * @param {Job} job - The job object to evaluate.
 *
 * @returns {JobProgressStatus} - Returns one of the following statuses:
 *   - "error" if there is an error (job.error is 1).
 *   - "progress" if there is no error (job.error is 0) and progress is less than the total steps.
 *   - "completed" if there is no error (job.error is 0) and progress is equal to the total steps.
 *   - `undefined` if none of the conditions are met.
 *
 * This function helps track the job’s current state, which could be useful for displaying
 * appropriate messages or indicators in the UI.
 */
export function getJobStatus(job: Job): JobProgressStatus {
  let status: JobProgressStatus;
  if (job.error == 1) {
    status = "error";
  } else if (job.error == 0) {
    if (job.progress < job.steps) {
      status = "progress";
    }
    if (job.steps == job.progress) {
      status = "completed";
    }
  }
  return status;
}
