//
// UpgradingInfraBanner.tsx
//

import Support from "@generic-components/Support";
import { MutableRefObject, useRef } from "react";

/**
 * UpgradingInfraBanner Component
 *
 * Renders a banner notifying users of infrastructure upgrades and potential service disruptions.
 * Allows users to contact support via email or by opening the support modal.
 */
export function UpgradingInfraBanner(): JSX.Element {
  // Reference to the Support component to trigger open action
  const supportRef: MutableRefObject<any> = useRef(null);

  // Opens the support modal when the "click here" link is clicked.
  const handleOpenSupport = () => {
    if (supportRef.current) {
      supportRef.current.open();
    }
  };

  // Render component
  return (
    <>
      <div className={"tw-flex tw-flex-col tw-gap-y-6"}>
        {/* Banner Title */}
        <div className="tw-dark:tw-text-text-title-dark tw-mb-4 tw-text-16px-semiBold tw-text-text-title-light">
          We're upgrading our infrastructure to serve you better!
        </div>

        {/* Banner Content */}
        <div className="tw-dark:tw-text-text-subTitle-dark tw-flex tw-flex-col tw-gap-y-2 tw-text-14px-light tw-text-text-subTitle-light">
          <div>
            During this time, you may experience intermittent service
            disruptions. <br /> We appreciate your patience and understanding as
            we work to enhance our systems.
          </div>
          <div>
            <ul>
              <li>
                <span className="tw-font-medium">Date</span>: Thursday, January
                2nd, 2025
              </li>
              <li>
                <span className="tw-font-medium">Expected Duration</span>: All
                day
              </li>
            </ul>
          </div>
          <div>
            For any immediate concerns, please contact our support team at{" "}
            <a
              href="mailto:dashboard@sqlitecloud.io"
              className="tw-cursor-pointer tw-font-medium tw-underline"
            >
              dashboard@sqlitecloud.io
            </a>{" "}
            or send your message by{" "}
            <span
              onClick={handleOpenSupport}
              className="tw-cursor-pointer tw-font-medium tw-underline"
            >
              clicking here
            </span>
          </div>
        </div>
        <div className=" tw-text-16px-semiBold">
          Thank you for your cooperation!
        </div>
      </div>

      {/* Support component for handling user queries */}
      <Support supportRef={supportRef} />
    </>
  );
}
